import { ModalCmp } from '@components/ModalCmp';
import { SecurityStatusConfigResponseBody } from '@core/@models/SecurityStatusCofigModel';
import { DataResponseBody } from '@core/@models/Types';
import { useDataPagination } from '@helpers/use-data-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecurityStatusConfigForm } from './SecurityStatusConfigForm';

export const SecurityStatusConfigList: React.FC = () => {
  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataPagination('/securities/status');
  const { t } = useTranslation(['common']);
  const [id, setId] = useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();

  const handleSelectRow = (record: DataResponseBody) => {
    return {
      onClick: () => {
        const r = record as SecurityStatusConfigResponseBody;
        setId(r.id);
        showModal();
      },
    };
  };
  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('Symbol'),
      dataIndex: 'code',
    },
    {
      title: t('expireDate'),
      dataIndex: 'expireDate',
    },
    {
      title: t('status'),
      dataIndex: 'status',
    },
    {
      title: t('tradingDate'),
      dataIndex: 'tradingDate',
    },
  ];

  return (
    <>
      <Table
        data-testid="security-status-config-table"
        className="selectable-table"
        pagination={pagination}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        onRow={handleSelectRow}
        style={{ marginTop: '10px' }}
      />
      <ModalCmp
        visible={visible}
        title={t('edit')}
        handleCancel={hideModal}
        width={1000}
      >
        <SecurityStatusConfigForm
          id={id}
          handleCancel={hideModal}
          forceFetchData={() => fetchData()}
        />
      </ModalCmp>
    </>
  );
};
