import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Collapse, Row, Space } from 'antd';
import React from 'react';

interface AttentionViewRoleProps {
  roles: string[];
}
const { Panel } = Collapse;

export const AttentionViewRole: React.FC<AttentionViewRoleProps> = ({
  roles,
}) => {
  const expandIcon = ({ isActive }: { isActive?: boolean }) =>
    isActive ? <ZoomOutOutlined /> : <ZoomInOutlined />;
  return (
    <>
      {roles.length > 0 ? (
        <Collapse
          className="collapse-role"
          expandIconPosition="right"
          expandIcon={expandIcon}
        >
          <Panel header="View all" key="1">
            {roles.map((role: string) => {
              return (
                <Row key={role}>
                  <Space direction="vertical">{role.toUpperCase()}</Space>
                </Row>
              );
            })}
          </Panel>
        </Collapse>
      ) : null}
    </>
  );
};
