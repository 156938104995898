import { ConfigDcaContext } from '@contexts/ConfigDcaContext';
import { SearchProvider } from '@contexts/SearchProvider';
import {
  CompanyType,
  CompanyTypeOption,
  DcaConfigQueryParam,
} from '@core/@models/DcaConfigModel';
import { CustomService } from '@core/services/custom.service';
import React, { useEffect, useState } from 'react';
import { DcaConfigSearch } from './DcaConfigSearch';
import { DcaConfigList } from './DcaConfigsList';

export const DcaConfig: React.FC = () => {
  const [companyTypes, setCompanyTypes] = useState<CompanyTypeOption[]>([]);
  const initialParam: DcaConfigQueryParam = {
    company: null,
    companyType: null,
    size: 15,
    page: 1,
  };

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = () => {
    CustomService.getData<CompanyType[]>('/companies/company-type').subscribe({
      next: (response: CompanyType[]) => {
        const options = response.map((r) => ({
          value: r.key,
          label: `${r.value} (${r.key})`,
        }));
        setCompanyTypes(options);
      },
    });
  };

  return (
    <ConfigDcaContext.Provider value={{ companyTypes }}>
      <SearchProvider<DcaConfigQueryParam> initialParam={initialParam}>
        <DcaConfigSearch />
        <DcaConfigList />
      </SearchProvider>
    </ConfigDcaContext.Provider>
  );
};
