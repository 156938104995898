import {
  Form,
  Upload,
  Button,
  DatePicker,
  Input,
  Radio,
  Table,
  Space,
  Tooltip,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import ResizeObserver from 'rc-resize-observer';
import { useTranslation } from 'react-i18next';
import { ApiService } from '@core/services/api.service';
import { UploadOutlined } from '@ant-design/icons';
import {
  AttentionRequest,
  AttentionResponseBody,
  RolesProperties,
  AttachesProperties,
} from '@core/@models/AttentionModel';
import { RadioChangeEvent } from 'antd/lib/radio';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonSet } from '@components/ButtonSet';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { ColumnsType } from 'antd/lib/table';
import { finalize } from 'rxjs/operators';
import { TableRowSelection } from 'antd/lib/table/interface';
import { convertByteToMB } from '@helpers/utils';
import { NotificationType, useNotification } from '@helpers/use-notification';

export interface AttentionTimeProps {
  id: number | null;
  handleCancel: () => void;
  fetchData: () => void;
}

interface AttentionPropertiesForm {
  publishBegin: moment.Moment[];
  headLine: string;
  content: string;
  attaches: AttachesProperties[];
  publish: string;
}

const { RangePicker } = DatePicker;
const format = 'YYYY-MM-DD';
const layout = { labelCol: { span: 3 }, wrapperCol: { span: 21 } };
const tableLayout = { wrapperCol: { span: 24 } };

export const AttentionForm: React.FC<AttentionTimeProps> = ({
  id,
  handleCancel,
  fetchData,
}) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [publishType, setPublishType] = useState<string>('displayRole');
  const [roles, setRoles] = useState<RolesProperties[]>([]);
  const [dataSourceRoles, setDataSourceRoles] = useState<RolesProperties[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const { open } = useNotification();
  const apiService = useMemo(() => new ApiService('/attentions'), []);
  const lang = LanguageService.getLanguage();

  const columns: ColumnsType<RolesProperties> = [
    {
      title: t('name'),
      dataIndex: 'name',
      width: '50%',
    },
    {
      title: t('description'),
      dataIndex: 'descriptionEn',
      key: 'en',
      width: '50%',
      className: 'last-column',
    },
    {
      title: t('description'),
      dataIndex: 'descriptionTh',
      key: 'th',
      width: '50%',
      className: 'last-column',
    },
  ];

  const filterColumnByLang = React.useCallback(
    (): ColumnsType<RolesProperties> =>
      columns.filter((c) => c.key === lang || !c.key),
    [lang]
  );

  const rowSelection: TableRowSelection<RolesProperties> = {
    selectedRowKeys: selectedRowKeys,
    onSelect: (record: RolesProperties, selected: boolean) => {
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.id]);
      } else {
        setSelectedRowKeys([
          ...selectedRowKeys.filter((key) => key != record.id),
        ]);
      }
    },
    onSelectAll: (selectedRows: boolean, changeRows: RolesProperties[]) =>
      setSelectedRowKeys([...changeRows.map((row: RolesProperties) => row.id)]),
  };

  useEffect(() => {
    if (!id) {
      fetchRoles(null);
      form.setFieldsValue({ publish: 'displayRole' });
      return;
    }
    setLoading(true);
    apiService
      .getDataById<string, AttentionResponseBody>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: AttentionResponseBody) => {
          fetchRoles(result);
          setFormFieldsValue(result);
        },
      });
  }, [id]);

  const fetchRoles = (data: AttentionResponseBody | null) => {
    setRolesLoading(true);
    CustomService.getData<RolesProperties[]>(`/attentions/roles`)
      .pipe(finalize(() => setRolesLoading(false)))
      .subscribe({
        next: (result: RolesProperties[]) => {
          setDataSourceRoles(result);
          setRoles(result);

          if (data) {
            const keys = (data.roles as RolesProperties[]).map(
              (role) => result.filter((r) => role.id === r.id)[0].id
            );
            setSelectedRowKeys([...keys]);
          }
        },
      });
  };

  const setFormFieldsValue = (result: AttentionResponseBody) => {
    const publishBegin = [
      moment(result.publishBegin, format),
      moment(result.publishExpire, format),
    ];
    const publish = result.publicFlag ? 'public' : 'displayRole';
    const attaches = result.attaches.map(
      (attach) => new File([], attach.linkUrl)
    );

    setFileList(attaches);
    setPublishType(publish);
    form.setFieldsValue({ ...result, publish, publishBegin });
  };

  const onFinish = (data: AttentionPropertiesForm) => {
    const requestData = transformData(data);
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };

  const transformData = (data: AttentionPropertiesForm): FormData => {
    const formData = new FormData();
    const m = {
      ...data.publishBegin.map((p) => p.format(format).toString()),
    };
    const { 0: publishBegin, 1: publishExpire } = m;
    const { headLine, content, publish } = data;
    const roleList = publish === 'public' ? [] : selectedRowKeys;
    const publicFlag = publish === 'public';
    const attaches = id ? data.attaches.filter((d) => d.id) : null;
    const requestData: AttentionRequest = {
      id,
      headLine,
      content,
      publishBegin,
      publishExpire,
      roles: roleList,
      publicFlag,
      attaches,
    };

    fileList.forEach((file: File, index: number) => {
      if (file.size != 0) {
        formData.append(data.attaches[index].reference, file);
      }
    });

    formData.append(
      '=data=',
      new File([JSON.stringify(requestData)], 'data.json', {
        type: 'application/json',
      })
    );
    return formData;
  };

  const handlePublishTypeChange = (event: RadioChangeEvent) =>
    setPublishType(event.target.value);

  const props = (index: number) => {
    return {
      beforeUpload: (file: File) => {
        let isMoreThanMaxSize = false;
        if (convertByteToMB(file.size) > 100) {
          open({
            type: NotificationType.ERROR,
            description: t('errorMessage.fileMaxSize', { maxSize: '100MB' }),
            disableDate: true,
          });
          isMoreThanMaxSize = true;
        }

        if (!isMoreThanMaxSize) {
          fileList[index] = file;
          setFileList([...fileList]);
          if (
            !form.getFieldValue('attaches')[index] ||
            form.getFieldValue('attaches')[index].reference === ''
          ) {
            const attachesTemp = form.getFieldValue('attaches');
            attachesTemp[index] = { reference: file.name };
            form.setFieldsValue({ attaches: attachesTemp });
          }
        }
        return false;
      },
      fileList: fileList[index] === undefined ? [] : [fileList[index]],
      showUploadList: { showRemoveIcon: false },
    };
  };

  const removeFile = (index: number) => fileList.splice(index, 1);

  const isUploadFormDisabled = (index: number) => {
    return fileList[index] !== undefined && fileList[index].size === 0;
  };

  const handleChange = (event: React.BaseSyntheticEvent) => {
    const { value } = event.target;
    const result = roles.filter((r) =>
      value ? r.name.toLowerCase().indexOf(value.toLowerCase()) > -1 : roles
    );
    setDataSourceRoles(result);
  };

  const disabledDate = (current: any) =>
    current && current < moment().add(-1, 'days');

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      data-testid="attention-form"
    >
      <Form.Item
        name="publishBegin"
        label={t('publish begin')}
        rules={[{ required: true }]}
      >
        <RangePicker
          style={{ lineHeight: '100%' }}
          placeholder={[t('begin'), t('end')]}
          ranges={{ Today: [moment(), moment()] }}
          disabledDate={disabledDate}
          allowClear={false}
          data-testid="publish-begin-picker"
        />
      </Form.Item>
      <Form.Item
        name="headLine"
        label={t('headline')}
        rules={[{ required: true }, { max: 200 }]}
      >
        <Input data-testid="headline-input" placeholder={t('headline')} />
      </Form.Item>
      <Form.Item name="content" label={t('content')} rules={[{ max: 800 }]}>
        <Input.TextArea
          data-testid="content-text-area"
          placeholder={t('content')}
        />
      </Form.Item>
      <Form.Item label={t('attachFiles')}>
        <Form.List name="attaches">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space
                  key={field.key}
                  align="baseline"
                  style={{ width: '100%' }}
                >
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.refference !== curValues.refference
                    }
                    style={{ width: '100%' }}
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, 'reference']}
                        fieldKey={[field.key, 'reference']}
                        style={{ marginBottom: '0px' }}
                        rules={[
                          {
                            max: 100,
                            message: t('errorMessage.maxLength', {
                              labelName: t('reference'),
                              max: 100,
                            }),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t('reference')}
                          style={{
                            marginBottom: '0px',
                            width: '340px',
                          }}
                          disabled={isUploadFormDisabled(index)}
                          data-testid={`${index}-reference-input`}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'upload']}
                    fieldKey={[field.key, 'upload']}
                    rules={[
                      {
                        required: !isUploadFormDisabled(index),
                        message: t('errorMessage.pleaseSelectFile'),
                      },
                    ]}
                  >
                    <Upload {...props(index)} data-testid={`${index}-uploader`}>
                      <Button
                        type="primary"
                        ghost
                        disabled={isUploadFormDisabled(index)}
                        icon={<UploadOutlined />}
                        style={{ width: '120px' }}
                        data-testid={`${index}-upload-button`}
                      >
                        {t('selectFile')}
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Tooltip title={t('button.delete')}>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                        removeFile(index);
                      }}
                    />
                  </Tooltip>
                </Space>
              ))}
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                data-testid="add-attachment-button"
              >
                {t('addAttachFile')}
              </Button>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item name="publish" label={t('publish')}>
        <Radio.Group name="publish" onChange={handlePublishTypeChange}>
          <Radio value="public" data-testid="public-radio-button">
            {t('public')}
          </Radio>
          <Radio value="displayRole" data-testid="display-role-radio-button">
            {t('displayRole')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('filter')}>
        <Input.Search
          {...layout}
          placeholder={`${t('name')}...`}
          name="name"
          onChange={handleChange}
          allowClear={true}
          data-testid="role-search-input"
        />
      </Form.Item>
      <Form.Item {...tableLayout}>
        <ResizeObserver>
          <Table
            data-testid="role-table"
            rowKey="id"
            size="small"
            className="virtual-table unselectable-table"
            columns={filterColumnByLang()}
            dataSource={dataSourceRoles}
            pagination={false}
            rowSelection={
              publishType === 'displayRole' ? rowSelection : undefined
            }
            scroll={{ y: 250, x: '100%' }}
            loading={rolesLoading}
          />
        </ResizeObserver>
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
