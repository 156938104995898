import { Routers } from '@core/@routes/Routers';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import localeData from 'dayjs/plugin/localeData';
import Auth0ProviderWithHistory from 'pages/auth/Auth0ProviderWithHistory';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
dayjs.extend(buddhistEra);
dayjs.extend(localeData);
const App = () => {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Routers />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
};

export default App;
