import { Search, TYPE } from '@components/Search';
import { useDataFetching } from '@helpers/use-data-fetching';
import React from 'react';
import { DataContext } from '@contexts/DataContext';
import { MdfConfigList } from './MdfConfigsList';
import { LanguageService } from '@core/services/language.service';

export const MdfConfig: React.FC = () => {
  const lang = LanguageService.getLanguage();
  const dataFetching = useDataFetching('/mdf-configs');
  const fieldProperties = [
    {
      labelName: 'name',
      name: lang === 'th' ? 'nameTh' : 'nameEn',
      type: TYPE.INPUT,
    },
    {
      labelName: 'System Type',
      name: 'systemType',
      type: TYPE.INPUT,
    },
    {
      labelName: 'Config Type',
      name: 'configType',
      type: TYPE.INPUT,
    },
  ];
  return (
    <DataContext.Provider value={dataFetching}>
      <Search fieldProps={fieldProperties} />
      <MdfConfigList />
    </DataContext.Provider>
  );
};
