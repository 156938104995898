import { SubMenuDetail } from '@core/@models/MenuModel';
import React, { useEffect } from 'react';
import { MenuTable } from '../MenuTable';
import { SubMenuFormModal } from './SubMenuFormModal';

interface SubMenuFormProps {
  onSubMenuChange: (data: SubMenuDetail[]) => void;
  subMenus: SubMenuDetail[] | undefined;
}

export const SubMenuFormPage: React.FC<SubMenuFormProps> = ({ ...props }) => {
  const [record, setRecord] = React.useState<{
    record?: SubMenuDetail;
    index?: number;
  }>({});

  const [subMenu, setSubMenu] = React.useState<SubMenuDetail[]>([]);

  useEffect(() => {
    props.onSubMenuChange(subMenu);
  }, [subMenu]);

  useEffect(() => {
    if (props.subMenus === undefined) return;
    setSubMenu(props.subMenus);
  }, [props.subMenus]);

  const handleRecord = (subMenuDetail: SubMenuDetail, index: number) => {
    setRecord({ record: subMenuDetail, index });
  };

  const onOk = (data?: SubMenuDetail, index?: number) => {
    if (data === undefined) return;
    if (index === undefined) {
      setSubMenu([...subMenu, data]);
    } else {
      subMenu[index] = data;
      setSubMenu([...subMenu]);
    }
    setRecord({});
  };

  const onCancel = () => {
    setRecord({});
  };

  const handleSortData = (data: SubMenuDetail[]) => {
    setSubMenu(data);
    props.onSubMenuChange(data);
  };

  return (
    <>
      <SubMenuFormModal
        record={record.record}
        index={record.index}
        onOk={onOk}
        onCancel={onCancel}
      />

      <MenuTable
        data={subMenu}
        pageName="subMenu"
        handleRecord={handleRecord}
        handleSortData={handleSortData}
      />
    </>
  );
};
