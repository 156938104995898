import { useEffect, useMemo, useState } from 'react';
import { ApiService } from '@core/services/api.service';
import { DataResponse } from '@core/@models/Types';
import { finalize } from 'rxjs/operators';

export interface FetchData<T extends DataResponse> {
  data: T[];
  loading: boolean;
  onSearch: <T extends DataResponse>(
    data: T[],
    fieldName: Array<keyof T>
  ) => T[];
  onSetQuery: (q: any) => void;
  fetchData: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useDataFetching = <T extends DataResponse>(
  url: string
): FetchData<T> => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState<any>();

  const apiService = useMemo(() => new ApiService(url), []);

  useEffect(() => {
    fetchData();
  }, [setData]);

  const onSetQuery = (query: any) => {
    setQ({ ...q, ...query });
  };

  const fetchData = () => {
    apiService
      .getData<T>()
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: T[]) => {
          setLoading(true);
          setData([...result]);
        },
      });
  };

  const onSearch = <T extends DataResponse>(
    dataSearch: T[],
    fieldNames: Array<keyof T>
  ): T[] => {
    let result: T[] = dataSearch;
    if (q) {
      fieldNames.forEach((f) => {
        result = result.filter((d) => {
          const fieldValue: unknown = d[f];
          if (typeof fieldValue === 'string') {
            const searchField: string = fieldValue;
            return q[f]
              ? searchField.toLowerCase().indexOf(q[f].toLowerCase()) > -1
              : [];
          } else if (
            typeof fieldValue === 'object' &&
            Array.isArray(fieldValue)
          ) {
            const searchField: any = fieldValue;
            return q[f]
              ? searchField.filter((s: any) => s.key === q[f]).length > 0
              : [];
          } else if (typeof fieldValue === 'boolean') {
            return q[f] === undefined ? true : q[f] === fieldValue;
          } else {
            return q[f] === undefined || q[f] === '';
          }
        });
      });
    }
    return result;
  };

  return { data, loading, fetchData, onSearch, onSetQuery, setLoading };
};
