import { Search, TYPE } from '@components/Search';
import {
  ConfigMenuContext,
  ConfigMenuFormSelect,
} from '@contexts/ConfigMenuContext';
import { DataContext } from '@contexts/DataContext';
import { ConfigMenuForm, MenuResponse } from '@core/@models/MenuModel';
import { CustomService } from '@core/services/custom.service';
import { useDataFetching } from '@helpers/use-data-fetching';
import { filterOption } from '@helpers/utils';
import React, { useEffect, useState } from 'react';
import { OverviewList } from './OverviewList';

const urlConfigData = 'menus/config-form';

const initialConfigData = {
  companyRoles: [],
  times: [],
  views: [],
  functions: [],
};

export const layoutBtnAdd: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  margin: '10px 0px',
};

export const Menu: React.FC = () => {
  const [fetchedData, setFetchedData] = useState<MenuResponse>();
  const handleFetchedData = (data: MenuResponse): void => setFetchedData(data);

  const [configData, setConfigData] = useState<ConfigMenuFormSelect>(
    initialConfigData
  );

  useEffect(() => {
    getConfigGroup();
  }, []);

  const getConfigGroup = () => {
    CustomService.getData<ConfigMenuForm>(urlConfigData).subscribe({
      next: (configMenuFormResult: ConfigMenuForm) => {
        if (!configMenuFormResult) return;

        const companyRoles = configMenuFormResult.companyRoles.map((r) => ({
          value: r.key,
          label: r.value,
        }));
        const times = configMenuFormResult.times.map((r) => ({
          value: r.id,
          label: r.name,
        }));
        const views = configMenuFormResult.views.map((r) => ({
          value: r,
          label: r,
        }));
        const functions = configMenuFormResult.functions.map((r) => ({
          value: r.id,
          label: r.nameTh,
          ...r,
        }));

        setConfigData({
          companyRoles,
          times,
          views,
          functions,
        });
      },
    });
  };

  const result = {
    ...configData,
    fetchedData,
    handleFetchedData,
    filterOption,
  };

  const dataFetching = useDataFetching('/menus');
  const fieldProperties = [
    {
      labelName: 'name',
      name: 'name',
      type: TYPE.INPUT,
    },
    {
      labelName: 'companyRole',
      name: 'companyRoles',
      type: TYPE.DROPDOWN,
      option: configData.companyRoles,
    },
  ];

  return (
    <ConfigMenuContext.Provider value={result}>
      <DataContext.Provider value={dataFetching}>
        <Search fieldProps={fieldProperties} />
        <OverviewList></OverviewList>
      </DataContext.Provider>
    </ConfigMenuContext.Provider>
  );
};
