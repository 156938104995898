import { useConfigMenuContext } from '@contexts/ConfigMenuContext';
import { Form, Select, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialValues } from './SubMenuFormModal';

const { Item } = Form;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const initialSubMenus = {
  functionId: undefined,
  timeId: undefined,
  view: undefined,
};

export interface SubMenuFormProps {
  form: FormInstance;
}

export const SubMenuForm: React.FC<SubMenuFormProps> = ({ form }) => {
  const { t } = useTranslation(['common']);
  const { times, functions, views, filterOption } = useConfigMenuContext();

  useEffect(() => {
    if (!form.getFieldValue('functionId'))
      form.setFieldsValue({ ...initialSubMenus });
  }, []);

  return (
    <>
      <Form
        {...formItemLayout}
        data-testid="sub-menu-form"
        form={form}
        initialValues={initialValues}
      >
        <Item
          name="functionId"
          label={t('function')}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder={t('function')}
            optionFilterProp="options"
            options={functions}
            filterOption={filterOption}
            data-testid="function-dropdown"
          />
        </Item>
        <Item name="timeId" label={t('time')} rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder={t('time')}
            optionFilterProp="options"
            options={times}
            filterOption={filterOption}
            data-testid="time-dropdown"
          />
        </Item>
        <Item name="view" label={t('preview')} rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder={t('preview')}
            optionFilterProp="options"
            options={views}
            filterOption={filterOption}
            data-testid="preview-dropdown"
          />
        </Item>
        <Item name="confirm" label={t('confirm')} valuePropName="checked">
          <Checkbox data-testid="confirm-checkbox" />
        </Item>
        <Item name="readOnly" label={t('read-only')} valuePropName="checked">
          <Checkbox data-testid="read-only-checkbox" />
        </Item>
        <Item name="id" hidden={true}></Item>
      </Form>
    </>
  );
};
