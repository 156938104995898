import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import reset from 'assets/icon/reset.png';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';

interface SearchButtonProps {
  handleSearch: () => void;
  handleReset: () => void;
}
export const SearchButton: React.FC<SearchButtonProps> = ({
  handleSearch,
  handleReset,
}: SearchButtonProps) => {
  const { t } = useTranslation(['common']);
  const styles = { width: 86, padding: '4px 10px' };
  return (
    <>
      <Button
        ghost
        type="primary"
        style={styles}
        onClick={handleReset}
        icon={<ResetIcon src={reset} />}
      >
        Reset
      </Button>
      <Button
        type="primary"
        style={styles}
        htmlType="submit"
        onClick={handleSearch}
        icon={<SearchOutlined />}
        data-testid="search-button"
      >
        {t('button.search')}
      </Button>
    </>
  );
};

export const ResetIcon = styled.img`
  padding: 0px 9px 4px 0px;
`;
