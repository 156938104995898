import { useModalManagement } from '@helpers/use-modal-management';
import { Form } from 'antd';
import React, { useEffect, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { MenuForm, MenuFormProps } from './MenuForm';
import { MenuDetail } from '@core/@models/MenuModel';
import { useTranslation } from 'react-i18next';
import { ModalComponent, ModalComponentProps } from '../ModalComponent';
import { ModalProps } from 'antd/lib/modal';
import { ButtonAdd } from '@components/ButtonAdd';
import { ButtonProps } from 'antd/lib/button';
import { layoutBtnAdd } from '..';

interface MenuFormModalProps {
  record: MenuDetail | null | undefined;
  onOk: (data: MenuDetail, index?: number) => void;
  index?: number;
  onCancel: () => void;
}

const initialValues = {
  id: null,
  nameTh: '',
  nameEn: '',
  descriptionTh: '',
  descriptionEn: '',
  subMenus: [],
};

export const MenuFormModal: React.FC<MenuFormModalProps> = ({
  record,
  ...props
}) => {
  const { visible, hideModal, showModal } = useModalManagement();
  const menuDetailRef = useRef<MenuDetail>();
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (record) {
      handleShowModal();
    }
  }, [record]);

  const handleShowModal = () => {
    form.setFieldsValue(record);
    showModal();
  };

  const handleClose = () => {
    props.onCancel();
    form.setFieldsValue(initialValues);
    hideModal();
  };

  const onChange = (data: MenuDetail) => {
    menuDetailRef.current = data;
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        props.onOk(menuDetailRef.current as MenuDetail, props.index);
        handleClose();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const buttonAddProps: ButtonProps = {
    type: 'dashed',
    style: { width: '30%' },
    icon: <PlusOutlined />,
    onClick: showModal,
  };

  const modalTitle = record?.id ? t('edit') : t('button.add');

  const modalProps: ModalProps = {
    visible,
    title: `${modalTitle} ${t('menu')}`,
    onCancel: handleClose,
  };

  const menuModalProps: ModalComponentProps<MenuFormProps> = {
    modalProps,
    componentProps: { form, record, onChange },
    component: MenuForm,
    handleSubmit,
  };

  return (
    <>
      <ButtonAdd buttonProps={buttonAddProps} layoutBtnAdd={layoutBtnAdd}>
        {t('button.add')} {t('menu')}
      </ButtonAdd>
      <ModalComponent {...menuModalProps}></ModalComponent>
    </>
  );
};
