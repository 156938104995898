import { useConfigMenuContext } from '@contexts/ConfigMenuContext';
import { ApiService } from '@core/services/api.service';
import { Form, Input, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuFormPage } from './menu/MenuFormPage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { MenuDetail, MenuResponse } from '@core/@models/MenuModel';
const { Item } = Form;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export interface OverviewFormProps {
  form: FormInstance;
  id: number | null | undefined;
  onChange: (data: MenuResponse) => void;
}

const initialValues = {
  name: '',
  descriptionTh: '',
  descriptionEn: '',
  companyRoles: [],
  menus: [],
  id: null,
};

export const OverviewForm: React.FC<OverviewFormProps> = ({
  form,
  id,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const {
    handleFetchedData,
    filterOption,
    companyRoles,
    fetchedData,
  } = useConfigMenuContext();
  const apiService = useMemo(() => new ApiService('/menus'), []);
  const menusRef = React.useRef<MenuDetail[]>([]);
  const menuFormRef = React.useRef<MenuResponse>();

  useEffect(() => {
    if (!id) {
      form.setFieldsValue(initialValues);
      handleFetchedData(initialValues);
      return;
    }
    getDataById();
  }, [id]);

  const getDataById = () => {
    apiService.getDataById<string, MenuResponse>(`${id}`).subscribe({
      next: (result: MenuResponse) => {
        form.setFieldsValue(result);
        handleFetchedData(result);
      },
    });
  };

  const onChange = (menuResponse: MenuResponse, menus: MenuDetail[]) => {
    const result = { ...menuResponse, ...{ menus } };
    props.onChange(result as MenuResponse);
  };

  const onValuesChange = (_: MenuResponse, allFields: MenuResponse) => {
    menuFormRef.current = allFields;
    onChange(allFields, menusRef.current);
  };

  const onChangeMenus = (menus: MenuDetail[]) => {
    menusRef.current = menus;
    onChange(form.getFieldsValue(), menusRef.current);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        name="overview-form"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        data-testid="overview-form"
      >
        <Item
          name="name"
          label={t('name')}
          rules={[{ required: true }, { max: 16 }]}
        >
          <Input placeholder={t('name')} data-testid="name-input" />
        </Item>
        <Item
          name="descriptionTh"
          label={t('descriptionTh')}
          rules={[{ max: 80 }]}
        >
          <Input
            placeholder={t('descriptionTh')}
            data-testid="description-th-input"
          />
        </Item>
        <Item
          name="descriptionEn"
          label={t('descriptionEn')}
          rules={[{ max: 80 }]}
        >
          <Input
            placeholder={t('descriptionEn')}
            data-testid="description-en-input"
          />
        </Item>
        <Item name="companyRoles" label={t('companyRole')}>
          <Select
            mode="multiple"
            placeholder={t('companyRole')}
            allowClear
            showSearch
            optionFilterProp="options"
            filterOption={filterOption}
            options={companyRoles}
            data-testid="company-role-dropdown"
          />
        </Item>
        {fetchedData?.menus && <Item name="id" hidden={true}></Item>}
      </Form>
      <MenuFormPage onChange={onChangeMenus} menus={fetchedData?.menus} />
    </>
  );
};
