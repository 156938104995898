import React from 'react';
import { Button } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import { MenuFormProps } from './menu/MenuForm';
import { OverviewFormProps } from './OverviewForm';
import { SubMenuFormProps } from './sub-menu/SubMenuForm';

type ModalType = MenuFormProps | OverviewFormProps | SubMenuFormProps;

export interface ModalComponentProps<T extends ModalType> {
  modalProps: ModalProps;
  componentProps: T;
  component: any;
  showConfirmModal?: () => void;
  handleSubmit: () => void;
  layoutBtnAdd?: React.CSSProperties;
}

export const ModalComponent = <T extends ModalType>({
  component: Component,
  modalProps,
  componentProps,
  ...props
}: ModalComponentProps<T> & {
  children?: React.ReactNode;
}): React.ReactElement => {
  const { t } = useTranslation(['common']);

  const footer: React.ReactNode = [
    <Button
      style={{ width: '80px' }}
      ghost
      type="primary"
      key="default"
      onClick={modalProps.onCancel}
    >
      {t('button.close')}
    </Button>,
    <Button
      style={{ width: '80px', float: 'right' }}
      key="primary"
      type="primary"
      htmlType="submit"
      onClick={props.handleSubmit}
      data-testid="save"
    >
      {t('button.save')}
    </Button>,
  ];
  return (
    <Modal
      centered
      destroyOnClose={true}
      maskClosable={false}
      width={1000}
      footer={footer}
      title={
        (componentProps as OverviewFormProps).id ? t('edit') : t('button.add')
      }
      {...modalProps}
    >
      <Component {...componentProps}></Component>
    </Modal>
  );
};
