import { Wrapper, Label } from '@components/Search';
import { SearchButton } from '@components/SearchButton';
import { CompanyTypeOption } from '@core/@models/DcaConfigModel';
import { StatusProperties } from '@core/@models/SecurityStatusCofigModel';
import { CustomService } from '@core/services/custom.service';
import { useSearchPagination } from '@helpers/use-search-pagination';
import { filterOption } from '@helpers/utils';
import { Card, Col, DatePicker, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

export const SecurityStatusConfigSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    name,
    securityType,
    expireDate,
    handleInputChange,
    handleSelectChange,
    handleCalendarChange,
    handleSearch,
    handleReset,
  } = useSearchPagination();

  const [option, setOption] = useState<{
    statuses: CompanyTypeOption[];
    types: CompanyTypeOption[];
  }>({ statuses: [], types: [] });
  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = () => {
    CustomService.getData<{
      statuses: StatusProperties[];
      types: StatusProperties[];
    }>('/securities/status/search-form').subscribe({
      next: ({
        statuses,
        types,
      }: {
        statuses: StatusProperties[];
        types: StatusProperties[];
      }) => {
        if (statuses.length === 0) return;
        const options = statuses.map((r) => ({
          value: `${r.key}`,
          label: r.name,
        }));
        const typeOptions = types.map((r) => ({
          value: `${r.key}`,
          label: r.name,
        }));
        setOption({ statuses: options, types: typeOptions });
      },
    });
  };
  return (
    <Card
      className="search-form"
      title={t('filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        <Col flex="50">
          <Wrapper>
            <Label>{t('Symbol')}</Label>
            <Input
              placeholder={`${t('Symbol')}...`}
              name="name"
              onChange={(e) => handleInputChange(e, 'name')}
              allowClear={true}
              value={name}
              data-testid="name"
            />
          </Wrapper>
        </Col>
        <Col flex="100">
          <Wrapper>
            <Label>{t('securityType')}</Label>
            <Select
              allowClear
              showSearch
              options={option.types}
              style={{ width: '100%' }}
              value={securityType || undefined}
              onChange={(value) => handleSelectChange(value, 'securityType')}
              placeholder={`${t('selectAll')}...`}
              optionFilterProp="options"
              filterOption={filterOption}
              data-testid="security-type-search-dropdown"
            />
          </Wrapper>
        </Col>
        <Col flex="50">
          <Wrapper>
            <Label>{t('expireDate')}</Label>
            <RangePicker
              style={{ width: '100%' }}
              placeholder={[`${t('begin')}...`, `${t('end')}...`]}
              ranges={{
                Today: [moment(), moment()],
              }}
              onCalendarChange={(values: any, formatString: [string, string]) =>
                handleCalendarChange(values, formatString, 'expireDate')
              }
              value={expireDate}
              data-testid="expire-date-picker"
            />
          </Wrapper>
        </Col>
      </Row>
    </Card>
  );
};
