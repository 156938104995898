import { DataResponse } from '@core/@models/Types';
import { FetchData } from '@helpers/use-data-fetching';
import React from 'react';

const DataContext = React.createContext<FetchData<DataResponse> | undefined>(
  undefined
);

function useDataContext(): FetchData<DataResponse> {
  const dataContext = React.useContext(DataContext);
  if (!dataContext) {
    throw new Error(
      'useDataContext must be used within the DataContext.Provider'
    );
  }
  return dataContext;
}

export { DataContext, useDataContext };
