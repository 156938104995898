import { SearchProvider } from '@contexts/SearchProvider';
import { AttentionQueryParam } from '@core/@models/AttentionModel';
import * as React from 'react';
import { AttentionApproveList } from './AttentionApproveList';
import { AttentionApproveSearch } from './AttentionApproveSearch';

export const AttentionApprove: React.FC = () => {
  const initialParam: AttentionQueryParam = {
    begin: null,
    end: null,
    size: 15,
    page: 1,
    status: 'draft',
  };

  return (
    <SearchProvider<AttentionQueryParam> initialParam={initialParam}>
      <AttentionApproveSearch />
      <AttentionApproveList />
    </SearchProvider>
  );
};
