import {
  RequestAuth0Token,
  ResponseAuth0Token,
  UserProfile,
} from '@core/@models/UserModel';
import { refreshToken$ } from '@core/axios';
import { AuthService } from '@core/services/auth.service';
import { CustomService } from '@core/services/custom.service';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { finalize } from 'rxjs/operators';

export interface ISiderMenu {
  menuGroupId: string;
  roleName: string;
  roleDescriptionTh: string;
  roleDescriptionEn: string;
  menus: IMenu[];
}

export interface IMenu {
  id: number;
  nameTh: string;
  nameEn: string;
  descriptionTh: string;
  descriptionEn: string;
  icon?: any;
  subMenus: ISubMenu[];
}
export interface ISubMenu {
  id: number;
  path: string;
  nameTh: string;
  nameEn: string;
  times: { from: string; to: string; isHoliday: boolean }[];
}
export interface AuthContextProps {
  siderMenus: ISiderMenu | undefined;
  roleId: string;
  loading: boolean;
  userProfile: UserProfile | undefined;
  defaultMenu: {
    menuId: string[];
    subMenuId: string[];
  };
  setDefaultMenu: React.Dispatch<
    React.SetStateAction<{
      menuId: string[];
      subMenuId: string[];
    }>
  >;
}

const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined
);

const AuthProvider: React.FC = ({ children }) => {
  const [siderMenus, setSiderMenus] = React.useState<ISiderMenu | undefined>();
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [defaultMenu, setDefaultMenu] = useState<{
    menuId: string[];
    subMenuId: string[];
  }>({ menuId: [''], subMenuId: [''] });
  const [loading, setLoading] = useState(true);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const roleId = urlSearchParams.get('roleId') || '';
  const menuId = urlSearchParams.get('menuId') || '';
  const subMenuId = urlSearchParams.get('subMenuId') || '';
  const routePath = urlSearchParams.get('path') || '';
  const code = urlSearchParams.get('code') || '';
  const jwt = urlSearchParams.get('JWT') || '';
  const refreshToken = urlSearchParams.get('REFRESH') || '';
  const history = useHistory();

  AuthService.setRoleId(roleId);
  AuthService.setMenuId(menuId);
  AuthService.setSubMenuId(subMenuId);

  useEffect(() => {
    const usePath = ['/auth/', '/direct/'];
    if (
      !usePath.includes(history.location.pathname) &&
      !AuthService.getToken()
    ) {
      window.location.replace(process.env.REACT_APP_SETDD_URL || '');
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (history.location.pathname === '/auth/') {
      AuthService.setToken(jwt);
      AuthService.setRefreshToken(refreshToken);
      history.push('/');
    }
  }, [jwt]);

  useEffect(() => {
    if (history.location.pathname === '/direct/') {
      AuthService.setRoutePath(`${routePath}/?${urlSearchParams}`);
    }
    if (!code) return;
    getOAuthToken();
  }, [code]);

  useEffect(() => {
    if (siderMenus) return;
    getMenu();
    getUserProfile();
    setDefaultMenu({
      menuId: [`${menuId}`],
      subMenuId: [`${subMenuId}`],
    });
  }, [siderMenus]);

  const getMenu = () => {
    CustomService.getData<ISiderMenu>('/users/menu', undefined, {
      'x-role-id': roleId,
    }).subscribe({
      next: (response: ISiderMenu) => {
        setSiderMenus(response);
        AuthService.setSideMenus(JSON.stringify(response));
      },
    });
  };

  const getUserProfile = () => {
    CustomService.getData<UserProfile>('/users/me')
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: UserProfile) => {
          setUserProfile(response);
        },
      });
  };

  const getOAuthToken = () => {
    const params: RequestAuth0Token = {
      code,
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
      redirect_uri: window.location.origin,
    };
    CustomService.postWithParam<RequestAuth0Token, ResponseAuth0Token>(
      `${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      params
    ).subscribe({
      next: (response: ResponseAuth0Token) => {
        const { access_token, refresh_token } = response;
        AuthService.setToken(access_token);
        AuthService.setRefreshToken(refresh_token);
        refreshToken$.next(access_token);
        history.push(`${AuthService.getRoutePath()}`);
      },
    });
  };

  const value = {
    siderMenus,
    roleId: AuthService.getRoleId() || '',
    loading,
    userProfile,
    defaultMenu,
    setDefaultMenu,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = (): AuthContextProps => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(
      'useAuthContext must be used within the AuthContext.Provider'
    );
  }
  return context;
};
export { useAuthContext, AuthProvider };
