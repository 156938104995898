import { Result, Space } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

export const PermissionDeny: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    state: { title, accessTimes },
  } = useLocation<{ title: string; accessTimes: string[] }>();
  const subTitle = () => {
    return (
      <>
        <Space>
          {t('denyAccessTimes', { workingTime: accessTimes[0] })}
          {accessTimes[1] &&
            t('denyHolidayTime', { holidayTime: accessTimes[1] })}
        </Space>
        <div>{t('pleaseContact')}</div>
      </>
    );
  };
  return <Result status="warning" title={t(title)} subTitle={subTitle()} />;
};
