import { ButtonAdd } from '@components/ButtonAdd';
import { DeleteButton } from '@components/DeleteButton';
import { ModalCmp } from '@components/ModalCmp';
import { DataResponseBody } from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { LanguageService } from '@core/services/language.service';
import { useDataPagination } from '@helpers/use-data-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { convertToDateShort } from '@helpers/utils';
import { Button, Row, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttentionForm } from './AttentionForm';
import { AttentionPreview } from './AttentionPreview';
import { AttentionViewRole } from './AttentionViewRole';

export const AttentionList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataPagination('/attentions');
  const [id, setId] = useState<number | null>(null);
  const [viewVisible, setViewVisible] = useState(false);
  const { visible, hideModal, showModal } = useModalManagement();
  const lang = LanguageService.getLanguage();
  const apiService = useMemo(() => new ApiService('/attentions'), []);

  const handleClick = (reqId: number | null) => setId(reqId);

  const showStatus = (status: string): React.ReactElement => (
    <Tag color={status === 'approved' ? 'green' : 'default'}>{status}</Tag>
  );

  const forceFetchData = () => {
    fetchData();
  };

  const setLink = (record: DataResponseBody): React.ReactElement => (
    <Typography.Link
      onClick={() => {
        handleClick(record.id);
        showViewModal();
      }}
    >
      {t('preview')}
    </Typography.Link>
  );

  const getRoleView = (value: string[]): React.ReactElement => (
    <AttentionViewRole roles={value} />
  );

  const showViewModal = () => setViewVisible(true);
  const hideViewModal = () => setViewVisible(false);

  const handleSelectRow = (record: DataResponseBody) => {
    handleClick(record.id);
    showModal();
  };

  const getRowLink = (
    text: string,
    record: DataResponseBody
  ): React.ReactElement => (
    <a onClick={() => handleSelectRow(record)}>{text}</a>
  );

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => forceFetchData(),
    });
  };

  const getDeleteButtonView = (
    record: DataResponseBody
  ): React.ReactElement => (
    <DeleteButton id={record.id || 0} onDelete={onDelete} />
  );

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('headline'),
      dataIndex: 'headLine',
      width: 200,
      render: getRowLink,
    },
    {
      title: t('begin'),
      dataIndex: 'publishBegin',
      width: 60,
      render: (value: string): string => convertToDateShort(value, lang),
    },
    {
      title: t('end'),
      dataIndex: 'publishExpire',
      width: 60,
      render: (value: string): string => convertToDateShort(value, lang),
    },
    {
      title: t('role'),
      dataIndex: 'roles',
      width: 100,
      render: getRoleView,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      width: 50,
      render: showStatus,
    },
    {
      width: 50,
      render: setLink,
    },
    {
      title: 'Action',
      width: 30,
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  return (
    <>
      <ButtonAdd showModal={showModal} handleClick={handleClick}></ButtonAdd>
      <Table
        data-testid="attention-table"
        className="attention-table selectable-table"
        id="attention-list"
        pagination={pagination}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        size="small"
      ></Table>
      <ModalCmp
        visible={visible}
        title={id ? t('edit') : t('button.add')}
        handleCancel={hideModal}
        width={1000}
      >
        <AttentionForm
          id={id}
          handleCancel={hideModal}
          fetchData={forceFetchData}
        ></AttentionForm>
      </ModalCmp>
      <ModalCmp
        visible={viewVisible}
        title={t('preview')}
        handleCancel={hideViewModal}
        width={1000}
      >
        <AttentionPreview id={id}>
          <Row className="button-set">
            <Button
              style={{ width: '80px' }}
              type="primary"
              ghost
              htmlType="button"
              onClick={hideViewModal}
            >
              {t('button.close')}
            </Button>
          </Row>
        </AttentionPreview>
      </ModalCmp>
    </>
  );
};
