import { Label, Wrapper } from '@components/Search';
import { SearchButton } from '@components/SearchButton';
import { useSearchPagination } from '@helpers/use-search-pagination';
import { Card, Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

export const AttentionApproveSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    publishDate,
    handleDateChange,
    handleSearch,
    handleReset,
  } = useSearchPagination();

  return (
    <Card
      className="search-form"
      title={t('filter')}
      style={{ marginBottom: '20px' }}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        <Col span={24}>
          <Wrapper>
            <Label>{t('publish begin')}</Label>
            <RangePicker
              style={{ width: '100%' }}
              placeholder={[`${t('begin')}...`, `${t('end')}...`]}
              ranges={{
                Today: [moment(), moment()],
              }}
              onCalendarChange={(values: any, formatString: [string, string]) =>
                handleDateChange(values, formatString)
              }
              value={publishDate}
              data-testid="publish-picker"
            />
          </Wrapper>
        </Col>
      </Row>
    </Card>
  );
};
