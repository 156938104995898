import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ModalFunc } from 'antd/lib/modal/confirm';
import i18n from '../i18n';
import { ModalFuncProps } from 'antd/lib/modal';
import exclamation from 'assets/icon/exclamation.png';
interface ModalProps {
  visible: boolean;
  title: string;
  width?: number;
  handleCancel: () => void;
}
export const ModalCmp: React.FC<ModalProps> = ({
  visible,
  title,
  children,
  width,
  handleCancel,
}) => {
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      width={width}
      maskClosable={false}
      centered
    >
      {children}
    </Modal>
  );
};

export const ConfirmModal: ModalFunc = ({ onOk }) => {
  const config: ModalFuncProps = {
    width: 320,
    cancelText: i18n.t('common:button.cancel'),
    okText: i18n.t('common:confirm'),
    autoFocusButton: null,
    cancelButtonProps: {
      ghost: true,
      type: 'primary',
      style: { width: 140 },
    },
    content: <Content>{i18n.t('common:confirmDeleteContent')}</Content>,
    okButtonProps: { style: { float: 'right', width: 140 } },
    icon: (
      <>
        <Img src={exclamation} />
        <Title>{i18n.t('common:confirmDeleteTitle')}</Title>
      </>
    ),
  };
  const result = { ...config, onOk };
  return Modal.confirm(result);
};

export const Img = styled.img`
  margin-left: 10px;
`;

export const Title = styled.span`
  font-weight: 700;
  margin-left: 20px;
  font-size: 16px;
`;

export const Content = styled.span`
  margin-left: 54px;
  font-size: 15px;
`;
