import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

const ProtectedRoute: React.FC<RouteProps> = ({ ...props }) => {
  const onRedirecting = () => <Spin />;
  return (
    <Route
      component={withAuthenticationRequired(() => null, {
        // Show a loading while the user waits to be redirected to the login page.
        onRedirecting,
      })}
      {...props}
    />
  );
};

export default ProtectedRoute;
