import { ButtonAdd } from '@components/ButtonAdd';
import { DeleteButton } from '@components/DeleteButton';
import { ModalCmp } from '@components/ModalCmp';
import { DataResponseBody } from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { LanguageService } from '@core/services/language.service';
import { useDataPagination } from '@helpers/use-data-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionForm } from './FunctionForm';

export const FunctionList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [id, setId] = useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();
  const lang = LanguageService.getLanguage();
  const apiService = useMemo(() => new ApiService('/functions'), []);
  const handleClick = (reqId: number | null) => setId(reqId);

  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataPagination('/functions');

  const onDelete = (reqId: number) => {
    apiService.deleteData<number>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const handleSelectRow = (record: DataResponseBody) => {
    return {
      onClick: () => {
        handleClick(record.id);
        showModal();
      },
    };
  };

  const getDeleteButtonView = (record: DataResponseBody): React.ReactElement =>
    record.id ? <DeleteButton id={record.id} onDelete={onDelete} /> : <></>;

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('name'),
      dataIndex: 'nameEn',
      key: 'en',
      width: '15%',
    },
    {
      title: t('name'),
      dataIndex: 'nameTh',
      key: 'th',
      width: '15%',
    },
    {
      title: t('description'),
      dataIndex: 'descriptionEn',
      key: 'en',
      width: '15%',
    },
    {
      title: t('description'),
      dataIndex: 'descriptionTh',
      key: 'th',
      width: '15%',
    },
    {
      title: t('URL'),
      dataIndex: 'url',
      width: '15%',
    },
    {
      title: t('newUrl'),
      dataIndex: 'newUrl',
      width: '15%',
    },
    {
      title: 'Server ID',
      dataIndex: 'apiServer',
      width: '6%',
    },
    {
      title: 'Action',
      width: '4%',
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const filterColumnByLang = React.useCallback(
    (): ColumnsType<DataResponseBody> =>
      columns.filter((c) => c.key === lang || !c.key),
    [lang, columns]
  );

  return (
    <>
      <ButtonAdd handleClick={handleClick} showModal={showModal}></ButtonAdd>
      <Table
        id="function-list"
        size="small"
        className="selectable-table"
        pagination={pagination}
        columns={filterColumnByLang()}
        dataSource={data}
        loading={loading}
        onRow={handleSelectRow}
        onChange={handleTableChange}
        data-testid="function-table"
        rowKey="id"
      ></Table>
      <ModalCmp
        visible={visible}
        title={id ? t('edit') : t('button.add')}
        handleCancel={hideModal}
        width={700}
      >
        <FunctionForm
          id={id}
          handleCancel={hideModal}
          forceFetchData={() => fetchData()}
        />
      </ModalCmp>
    </>
  );
};
