import { AttentionPulishFile } from '@core/@models/AttentionModel';
import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { CustomService } from '@core/services/custom.service';
import FileSaver from 'file-saver';
import { Button, Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { NotificationType, useNotification } from '@helpers/use-notification';
import { finalize } from 'rxjs/operators';

interface AttentionDownloadFileProps {
  id: number | null;
  attaches: AttentionPulishFile[] | undefined;
}
export const AttentionDownloadFile: React.FC<AttentionDownloadFileProps> = ({
  id,
  attaches,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { open } = useNotification();

  const downloadFile = ({ id: reqAttachId, linkUrl }: AttentionPulishFile) => {
    setLoading(true);
    CustomService.downloadFile(`/attentions/${id}/attach/${reqAttachId}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: Blob) => {
          const blob = new Blob([result]);
          FileSaver.saveAs(blob, linkUrl);
        },
        error: (error) => {
          console.error('Download Approve File: ', error);
          open({
            type: NotificationType.ERROR,
            description: t('common:downloadFailed'),
            disableDate: true,
          });
        },
      });
  };

  return (
    <>
      <Divider style={{ marginBottom: '5px' }} />
      <ul
        id="download-file"
        style={{
          listStyleType: 'none',
          padding: '0px',
          width: 'auto',
          margin: '10px 0px 0px 0px',
        }}
      >
        <Spin spinning={loading}>
          {attaches?.map((v, index) => (
            <li
              key={index}
              style={{
                width: '30%',
                margin: '5px 10px 5px 0px',
                display: 'inline-block',
              }}
            >
              <Button
                style={{
                  textAlign: 'left',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
                icon={<DownloadOutlined style={{ fontSize: '16px' }} />}
                onClick={() => downloadFile(v)}
                data-testid={`${index}-download-button`}
              >
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '220px',
                  }}
                >
                  {v.reference}
                </span>
              </Button>
            </li>
          ))}
        </Spin>
      </ul>
    </>
  );
};
