import { CompanyTypeOption } from '@core/@models/DcaConfigModel';
import React from 'react';

export interface DcaContextProps {
  companyTypes: CompanyTypeOption[];
}

const ConfigDcaContext = React.createContext<DcaContextProps | undefined>(
  undefined
);

function useConfigDcaContext(): DcaContextProps {
  const configDcaContext = React.useContext(ConfigDcaContext);
  if (!configDcaContext) {
    throw new Error(
      'useConfigDcaContext must be used within the DataContext.Provider'
    );
  }
  return configDcaContext;
}

export { useConfigDcaContext, ConfigDcaContext };
