import { Search, TYPE } from '@components/Search';
import { useDataFetching } from '@helpers/use-data-fetching';
import React from 'react';
import { SystemConfigList } from './SystemConfigList';
import { DataContext } from '@contexts/DataContext';

export const SystemConfig: React.FC = () => {
  const dataFetching = useDataFetching('/system-configs');
  const fieldProperties = [
    { labelName: 'name', name: 'key', type: TYPE.INPUT },
  ];
  return (
    <DataContext.Provider value={dataFetching}>
      <Search fieldProps={fieldProperties} />
      <SystemConfigList />
    </DataContext.Provider>
  );
};
