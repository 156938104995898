import { useState } from 'react';

interface ModalManagement {
  visible: boolean;
  hideModal: () => void;
  showModal: () => void;
}
export const useModalManagement = (): ModalManagement => {
  const [visible, setVisible] = useState(false);
  const hideModal = () => setVisible(false);
  const showModal = () => setVisible(true);

  return {
    visible,
    hideModal,
    showModal,
  };
};
