import { Language } from '@components/Language';
import { useAuthContext } from '@contexts/AuthProvider';
import { LanguageService } from '@core/services/language.service';
import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { HeaderLogo } from './HeaderLogo';

export const Headers: React.FC = () => {
  try {
    const { siderMenus, userProfile } = useAuthContext();
    const lang = LanguageService.getLanguage();
    const descriptionKey =
      lang === 'th' ? 'roleDescriptionTh' : 'roleDescriptionEn';
    return (
      <Header>
        <HeaderLogo />
        <Wrapper>
          <UserInfo>
            <Name>{`Welcome, ${userProfile?.nameTh}`}</Name>
            <RoleNames>{siderMenus && siderMenus[descriptionKey]}</RoleNames>
          </UserInfo>
          <Language />
        </Wrapper>
      </Header>
    );
  } catch (e) {
    return (
      <Header>
        <HeaderLogo />
      </Header>
    );
  }
};
const Header = styled(Layout.Header)`
  display: flex;
  padding: 0px;
  align-items: center;
`;

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: 40px;
  display: flex;
`;

const UserInfo = styled.span`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  align-items: end;
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: right;
`;

const RoleNames = styled.span`
  font-size: 10px;
  line-height: 16px;
  color: #9da6ad;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  text-align: right;
`;
