import { PlusOutlined } from '@ant-design/icons';
import { ButtonAdd } from '@components/ButtonAdd';
import { useConfigMenuContext } from '@contexts/ConfigMenuContext';
import { MenuDetail, SubMenuDetail } from '@core/@models/MenuModel';
import { useModalManagement } from '@helpers/use-modal-management';
import { Form } from 'antd';
import { Store } from 'antd/es/form/interface';
import { ButtonProps } from 'antd/lib/button';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { layoutBtnAdd } from '..';
import { ModalComponent, ModalComponentProps } from '../ModalComponent';
import { SubMenuForm, SubMenuFormProps } from './SubMenuForm';

interface SubMenuFormModalProps {
  record: SubMenuDetail | MenuDetail | null | undefined;
  onOk: (data?: SubMenuDetail, index?: number) => void;
  index?: number;
  onCancel: () => void;
}

export const initialValues = {
  functionId: '',
  timeId: '',
  view: '',
  confirm: null,
  readOnly: null,
  id: null,
};

export const SubMenuFormModal: React.FC<SubMenuFormModalProps> = ({
  record,
  ...props
}) => {
  const { visible, hideModal, showModal } = useModalManagement();
  const [form] = Form.useForm();
  const { functions } = useConfigMenuContext();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (record) {
      handleShowModal();
    }
  }, [record]);

  const handleShowModal = () => {
    form.setFieldsValue(record);
    showModal();
  };

  const handleClose = () => {
    props.onCancel();
    form.setFieldsValue(initialValues);
    hideModal();
  };

  const getFunctionName = (value: Store) => {
    const result = functions.find((f) => f.value === value['functionId']);
    if (result === undefined) return;
    const { nameTh, nameEn, descriptionEn, descriptionTh } = result;
    return { nameTh, nameEn, descriptionEn, descriptionTh };
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((value) => {
        const functionName = getFunctionName(value);
        const subMenus = { ...functionName, ...value };
        props.onOk(subMenus as SubMenuDetail, props.index);
        handleClose();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const buttonAddProps: ButtonProps = {
    type: 'dashed',
    style: { width: '30%' },
    icon: <PlusOutlined />,
    onClick: showModal,
  };

  const modalTitle = record?.id ? t('edit') : t('button.add');

  const menuModalProps: ModalComponentProps<SubMenuFormProps> = {
    componentProps: { form },
    component: SubMenuForm,
    modalProps: {
      visible,
      title: `${modalTitle} ${t('sub-menu')}`,
      onCancel: handleClose,
    },
    handleSubmit,
  };

  return (
    <>
      <ButtonAdd buttonProps={buttonAddProps} layoutBtnAdd={layoutBtnAdd}>
        {t('button.add')} {t('sub-menu')}
      </ButtonAdd>
      <ModalComponent {...menuModalProps}></ModalComponent>
    </>
  );
};
