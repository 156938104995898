import React from 'react';
import i18n from '../i18n';
import moment from 'moment';
import styled from 'styled-components';
import { notification, Row } from 'antd';
import { convertToDateTimeShort } from './utils';
import { LanguageService } from '@core/services/language.service';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

export enum NotificationType {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export interface NotificationProps {
  type: NotificationType;
  description: string;
  disableDate?: boolean;
}

export interface Notification {
  open: (props: NotificationProps) => void;
}

export const useNotification = (): Notification => {
  const open = ({ type, description, disableDate }: NotificationProps) => {
    const lang = LanguageService.getLanguage();
    const isSuccess = type === NotificationType.SUCCESS;
    const date = convertToDateTimeShort(moment().format(), lang);

    notification.open({
      duration: 4,
      icon: isSuccess ? <CheckIcon /> : <CloseIcon />,
      message: (
        <Title>
          {isSuccess
            ? NotificationType.SUCCESS
            : i18n.t('common:errorMessage.foundAnError')}
        </Title>
      ),
      description: (
        <>
          <Row>{description}</Row>
          {!disableDate ? (
            <Row>
              <Date>{date}</Date>
            </Row>
          ) : undefined}
        </>
      ),
      placement: 'bottomRight',
      style: {
        width: 320,
      },
    });
  };

  return {
    open,
  };
};

export const CheckIcon = styled(CheckCircleFilled)`
  color: #52c41a;
`;

export const CloseIcon = styled(CloseCircleFilled)`
  color: #f5222d;
`;

export const Title = styled.span`
  font-weight: 700;
`;

export const Date = styled.span`
  font-size: 12px;
  color: rgba(35, 31, 32, 0.45);
`;
