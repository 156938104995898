import { Search, TYPE } from '@components/Search';
import { ConfigDcaContext } from '@contexts/ConfigDcaContext';
import { DataContext } from '@contexts/DataContext';
import { CompanyType, CompanyTypeOption } from '@core/@models/DcaConfigModel';
import { CustomService } from '@core/services/custom.service';
import { useDataFetching } from '@helpers/use-data-fetching';
import React, { useState } from 'react';
import { ConfigCreatorList } from './ConfigCreatorList';

export const ConfigCreator: React.FC = () => {
  const dataFetching = useDataFetching('/test-creator-configs');
  const [companyTypes, setCompanyTypes] = useState<CompanyTypeOption[]>([]);

  React.useEffect(() => {
    getOptions();
  }, []);

  const getOptions = () => {
    CustomService.getData<CompanyType[]>('/companies/company-type').subscribe({
      next: (response: CompanyType[]) => {
        const options = response.map((r) => ({
          value: r.key,
          label: `${r.value} (${r.key})`,
        }));
        setCompanyTypes(options);
      },
    });
  };

  const fieldProperties = [
    {
      labelName: 'loginName',
      name: 'loginName',
      type: TYPE.INPUT,
    },
    {
      labelName: 'companyType',
      name: 'companyType',
      type: TYPE.DROPDOWN,
      option: companyTypes,
    },
  ];
  return (
    <DataContext.Provider value={dataFetching}>
      <ConfigDcaContext.Provider value={{ companyTypes }}>
        <Search fieldProps={fieldProperties} />
        <ConfigCreatorList />
      </ConfigDcaContext.Provider>
    </DataContext.Provider>
  );
};
