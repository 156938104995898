import { AttentionPulishFile } from '@core/@models/AttentionModel';
import { Button, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomService } from '@core/services/custom.service';
import { AttentionPreview } from 'pages/attention/AttentionPreview';
import { NotificationOutlined } from '@ant-design/icons';

export interface AttentionApproveFormProps {
  id: number | null;
  handleCancel: () => void;
  forceFetchData: () => void;
}

export interface AttentionApproveFormState {
  headLine: string;
  content: string;
  attaches: AttentionPulishFile[];
}

export const AttentionApproveForm: React.FC<AttentionApproveFormProps> = ({
  id,
  handleCancel,
  forceFetchData,
}) => {
  const { t } = useTranslation(['common']);

  const onPublish = () => {
    CustomService.createData(`/attentions/${id}/approve`).subscribe({
      next: () => {
        forceFetchData();
        handleCancel();
      },
      error: (error) => console.error('Publish attention approve: ', error),
    });
  };

  return (
    <AttentionPreview id={id}>
      <Row className="button-set" style={{ display: 'block' }}>
        <Button
          style={{ width: '100px' }}
          type="primary"
          ghost
          htmlType="button"
          onClick={handleCancel}
        >
          {t('button.close')}
        </Button>
        <Button
          style={{ float: 'right' }}
          type="primary"
          onClick={onPublish}
          icon={<NotificationOutlined />}
          data-testid="publish-buttton"
        >
          {t('button.publish')}
        </Button>
      </Row>
    </AttentionPreview>
  );
};
