import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { OverviewFormModal } from './OverviewFormModal';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '@contexts/DataContext';
import { DataResponse, DataResponseBody } from '@core/@models/Types';
import { LanguageService } from '@core/services/language.service';
import { CompanyRole } from '@core/@models/MenuModel';
import { ApiService } from '@core/services/api.service';
import { DeleteButton } from '@components/DeleteButton';

export const OverviewList: React.FC = () => {
  const [id, setId] = useState<number | null>();
  const { t } = useTranslation(['common']);
  const { data, loading, onSearch, fetchData } = useDataContext();
  const handleId = (reqId: number | null | undefined) => setId(reqId);
  const lang = LanguageService.getLanguage();
  const apiService = useMemo(() => new ApiService('/menus'), []);

  const handleSelectRow = (record: DataResponse) => {
    return {
      onClick: () => handleId(record.id),
    };
  };

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const getDeleteButtonView = (record: DataResponseBody): React.ReactElement =>
    record.id ? <DeleteButton id={record.id} onDelete={onDelete} /> : <></>;

  const getCompanyRoleView = (value: CompanyRole[]): React.ReactElement => (
    <>
      {value.map((v) => (
        <p key={v.key} style={{ margin: '0px' }}>
          {v.value} ({v.key})
        </p>
      ))}
    </>
  );

  const columns: ColumnsType<DataResponse> = [
    {
      title: t('name'),
      dataIndex: 'name',
      width: 80,
      sorter: {
        compare: (a, b) => (a.name || '').localeCompare(b.name || ''),
      },
    },
    {
      title: t('description'),
      dataIndex: 'descriptionEn',
      width: 100,
      key: 'en',
      sorter: {
        compare: (a, b) =>
          (a.descriptionEn || '').localeCompare(b.descriptionEn || ''),
      },
    },
    {
      title: t('description'),
      dataIndex: 'descriptionTh',
      width: 100,
      key: 'th',
      sorter: {
        compare: (a, b) =>
          (a.descriptionTh || '').localeCompare(b.descriptionTh || ''),
      },
    },
    {
      title: t('companyRole'),
      dataIndex: 'companyRoles',
      width: 80,
      render: getCompanyRoleView,
    },
    {
      title: 'Action',
      width: 30,
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const filterColumnByLang = React.useCallback(
    (): ColumnsType<DataResponse> =>
      columns.filter((c) => c.key === lang || !c.key),
    [lang, columns]
  );

  return (
    <>
      <OverviewFormModal id={id} fetchData={fetchData} handleId={handleId} />
      <Table
        data-testid="menu-table"
        className="selectable-table"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('common:totalItem', { total }),
        }}
        onRow={handleSelectRow}
        columns={filterColumnByLang()}
        dataSource={onSearch<DataResponse>(data, ['name', 'companyRoles'])}
        loading={loading}
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey="id"
        size="small"
      />
    </>
  );
};
