import { Button } from 'antd';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/lib/button';

interface ButtonAddProps {
  showModal?: () => void;
  handleClick?: (id: number | null) => void;
  buttonProps?: ButtonProps;
  layoutBtnAdd?: React.CSSProperties;
}

const layoutBtnAdd = {
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '10px 0px',
};
export const ButtonAdd: React.FC<ButtonAddProps> = ({
  showModal,
  handleClick,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div style={props.layoutBtnAdd || layoutBtnAdd}>
      <Button
        data-testid="add"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          handleClick && handleClick(null);
          showModal && showModal();
        }}
        {...props.buttonProps}
      >
        {children || t('common:button.add')}
      </Button>
    </div>
  );
};
