import { ModalCmp } from '@components/ModalCmp';
import { AccessTimeResponse } from '@core/@models/AccessTimeModel';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { AccessTimeForm } from './AccessTimeForm';
import { useDataContext } from '@contexts/DataContext';
import { DataResponse } from '@core/@models/Types';
import { useModalManagement } from '@helpers/use-modal-management';
import { ButtonAdd } from '@components/ButtonAdd';
import { ApiService } from '@core/services/api.service';
import { DeleteButton } from '@components/DeleteButton';
import { CustomTable } from '@components/CustomTable';

export const AccessTimeList: React.FC = () => {
  const [id, setId] = useState<number | null>(null);
  const { t } = useTranslation(['common']);
  const { data, loading, onSearch, fetchData } = useDataContext();
  const { visible, hideModal, showModal } = useModalManagement();
  const apiService = useMemo(() => new ApiService('/access-times'), []);

  const handleClick = (reqId: number | null) => setId(reqId);

  const handleSelectRow = (record: AccessTimeResponse) => {
    return {
      onClick: () => {
        handleClick(record.id);
        showModal();
      },
    };
  };

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const getDeleteButtonView = (
    record: AccessTimeResponse
  ): React.ReactElement => <DeleteButton id={record.id} onDelete={onDelete} />;

  const columns: ColumnsType<AccessTimeResponse> = [
    {
      title: t('name'),
      dataIndex: 'time',
      width: '35%',
      sorter: {
        compare: (a, b) => (a.time || '').localeCompare(b.time || ''),
      },
    },
    {
      title: t('properties'),
      dataIndex: 'properties',
      width: '55%',
      sorter: {
        compare: (a, b) =>
          (a.properties || '').length - (b.properties || '').length,
      },
    },
    {
      title: 'Action',
      width: '10%',
      className: 'last-column',
      align: 'center',
      render: getDeleteButtonView,
    },
  ];

  return (
    <>
      <ButtonAdd handleClick={handleClick} showModal={showModal} />
      <CustomTable<AccessTimeResponse>
        data-testid="access-time-table"
        onRow={handleSelectRow}
        columns={columns}
        dataSource={onSearch<DataResponse>(data, ['time'])}
        loading={loading}
      />
      <ModalCmp
        visible={visible}
        title={id ? t('edit') : t('button.add')}
        handleCancel={hideModal}
        width={800}
      >
        <AccessTimeForm id={id} handleCancel={hideModal} />
      </ModalCmp>
    </>
  );
};
