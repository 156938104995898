import {
  DataResponseBody,
  QueryParam,
  DataResponsePagination,
} from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

export interface FetchingDataPagination<T extends QueryParam> {
  data: DataResponseBody[];
  loading: boolean;
  pagination: TablePaginationConfig;
  handleTableChange: (
    tablePagination: TablePaginationConfig,
    _: Record<string, React.Key[] | null>,
    sorter: SorterResult<DataResponseBody> | SorterResult<DataResponseBody>[]
  ) => void;
  fetchData: (queryParam: T) => void;
}

export const useDataFetchingPagination = <T extends QueryParam>(
  queryParam: T,
  url: string
): FetchingDataPagination<T> => {
  const { t } = useTranslation();
  const apiService = React.useMemo(() => new ApiService(url), []);
  const [data, setData] = useState<DataResponseBody[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    showSizeChanger: false,
    showTotal: (total) => t('common:totalItem', { total }),
  });

  useEffect(() => {
    fetchData(queryParam);
  }, [queryParam]);

  const getSortString = (sorter: SorterResult<DataResponseBody>): string => {
    const sorterField = `${sorter.field}`;
    const abbrSortType = sorter.order?.substring(0, sorter.order.length - 3);
    return `${sorterField},${abbrSortType}`;
  };
  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    _: Record<string, React.Key[] | null>,
    sorter: SorterResult<DataResponseBody> | SorterResult<DataResponseBody>[]
  ) => {
    if (Array.isArray(sorter)) return;
    const sort =
      Object.keys(sorter).length === 0 ? null : getSortString(sorter);
    const { current: page, pageSize: size } = tablePagination;
    setPagination(Object.assign({}, pagination, tablePagination));
    const param = Object.assign({}, { ...queryParam, page, size, sort });
    fetchData(param);
  };

  const fetchData = (param: T) => {
    setLoading(true);
    apiService
      .getDataPagable<DataResponsePagination>(param)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DataResponsePagination) => {
          const { content, totalElements: total } = result;
          setData(content);
          const { page: current, size: pageSize } = param;
          setPagination({ ...pagination, current, pageSize, total });
        },
      });
  };
  return {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  };
};
