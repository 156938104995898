import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageService } from '@core/services/language.service';
import dayjs from 'dayjs';
import instance from '@core/axios';
import styled from 'styled-components';
import thaiFlag from 'assets/thai.svg';
import engFlag from 'assets/english.svg';

const languages = [
  { key: 'th', alt: 'flag thai', src: thaiFlag },
  { key: 'en', alt: 'flag english', src: engFlag },
];

export const Language: React.FC = () => {
  const { i18n } = useTranslation();
  const lang = LanguageService.getLanguage();
  dayjs.locale(lang);

  const switchingLanguage = (value: string) => {
    LanguageService.setLanguage(value);
    i18n.changeLanguage(value);
    instance.defaults.headers = {
      ...instance.defaults.headers,
      ...{ 'Accept-Language': value },
    };
    dayjs.locale(value);
  };

  return (
    <>
      {languages.map((item, i) => (
        <LangFlag
          key={i}
          alt={item.alt}
          src={item.src}
          className={item.key === lang ? 'active' : ''}
          onClick={() => switchingLanguage(item.key)}
        />
      ))}
    </>
  );
};

const LangFlag = styled.img`
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
  }
`;
