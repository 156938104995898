import { ButtonSet } from '@components/ButtonSet';
import {
  CompanyTypeOption,
  DcaConfigFormProps,
} from '@core/@models/DcaConfigModel';
import {
  SecurityStatusConfigResponseBody,
  StatusProperties,
  SecurityStatusConfigFormData,
} from '@core/@models/SecurityStatusCofigModel';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { filterOption } from '@helpers/utils';
import { DatePicker, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
const { Item } = Form;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};
const format = 'YYYY-MM-DD';
export const SecurityStatusConfigForm: React.FC<DcaConfigFormProps> = ({
  id,
  handleCancel,
  forceFetchData,
}) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [statusOption, setStatusOption] = useState<CompanyTypeOption[]>([]);
  const apiService = useMemo(() => new ApiService('/securities/status'), []);

  useEffect(() => {
    getForm();
    getOptions();
  }, []);

  const getOptions = () => {
    CustomService.getData<{ statuses: StatusProperties[] }>(
      '/securities/status/search-form'
    ).subscribe({
      next: ({ statuses }: { statuses: StatusProperties[] }) => {
        if (statuses.length === 0) return;
        const options = statuses.map((r) => ({
          value: `${r.key}`,
          label: r.name,
        }));
        setStatusOption(options);
      },
    });
  };

  const getForm = () => {
    if (!id) return;
    setLoading(true);
    CustomService.getData<SecurityStatusConfigResponseBody>(
      `/securities/status/${id}`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: tranformData,
      });
  };

  const tranformData = (response: SecurityStatusConfigResponseBody) => {
    const result = {
      ...response,
      expireDate: response.expireDate
        ? moment(response.expireDate, format)
        : null,
      tradingDate: response.tradingDate ? moment(response.tradingDate) : null,
    };
    form.setFieldsValue(result);
  };
  const convertMomentToString = (date: moment.Moment): string | null => {
    if (!date) return null;
    return date.format(format);
  };

  const onFinish = (formData: SecurityStatusConfigFormData) => {
    const { tradingDate, expireDate } = formData;
    const requestData = {
      id,
      ...formData,
      tradingDate: convertMomentToString(tradingDate),
      expireDate: convertMomentToString(expireDate),
    };
    setLoading(true);
    apiService
      .updateData(requestData)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          forceFetchData();
          handleCancel();
        },
      });
  };

  return (
    <Form
      {...layout}
      data-testid="security-status-config-form"
      onFinish={onFinish}
      form={form}
    >
      <Item name="code" label={t('symbol')}>
        <Input placeholder={t('symbol')} disabled />
      </Item>
      <Item name="name" label={t('name')}>
        <Input placeholder={t('name')} disabled />
      </Item>
      <Item name="expireDate" label={t('expireDate')}>
        <DatePicker format={'YYYY-MM-DD'} placeholder={t('expireDate')} />
      </Item>
      <Item name="tradingDate" label={t('tradingDate')}>
        <DatePicker format={'YYYY-MM-DD'} placeholder={t('tradingDate')} />
      </Item>
      <Item name="status" label={t('status')}>
        <Select
          allowClear={false}
          options={statusOption}
          optionFilterProp="options"
          filterOption={filterOption}
          data-testid="status-form"
        />
      </Item>

      <Item
        name="remark"
        label={t('remark')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <TextArea
          data-testid="remark"
          showCount
          maxLength={100}
          placeholder={t('remark')}
        />
      </Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
