import { ResponseData } from '@core/@models/Types';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const CustomTable = <T extends ResponseData>(
  props: TableProps<T>
): React.ReactElement => {
  const { t } = useTranslation(['common']);
  return (
    <Table
      className="selectable-table"
      pagination={{
        pageSize: 15,
        showSizeChanger: false,
        showTotal: (total) => t('common:totalItem', { total }),
      }}
      rowKey="id"
      size="small"
      sortDirections={['ascend', 'descend', 'ascend']}
      {...props}
    />
  );
};
