import {
  Form,
  Space,
  Button,
  TimePicker,
  Checkbox,
  Input,
  Tooltip,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  AccessTimeProperties,
  AccessTimeResponse,
  AccessTimeResquest,
} from '@core/@models/AccessTimeModel';
import { ApiService } from '@core/services/api.service';
import { useDataContext } from '@contexts/DataContext';
import { ButtonSet } from '@components/ButtonSet';
import { finalize } from 'rxjs/operators';

export interface AccessTimeFormProps {
  id: number | null;
  handleCancel: () => void;
}
interface TimePropertiesForm {
  range: moment.Moment[];
  isHoliday: boolean;
}

const { RangePicker } = TimePicker;

export const AccessTimeForm: React.FC<AccessTimeFormProps> = ({
  id,
  handleCancel,
}) => {
  const { t } = useTranslation(['common']);
  const format = 'HH:mm';
  const [form] = Form.useForm();
  const apiService = useMemo(() => new ApiService('/access-times'), []);
  const { fetchData, loading, setLoading } = useDataContext();

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        time: '',
        timeProperties: [
          {
            range: [],
            isHoliday: false,
          },
        ],
      });
      return;
    }
    getDataById();
  }, [id]);

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, AccessTimeResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: AccessTimeResponse) => {
          const { time, properties } = result;
          const timeProperties = setTimeProperties(properties);
          form.setFieldsValue({ time, timeProperties });
        },
      });
  };

  const setTimeProperties = (
    properties: Array<AccessTimeProperties> | string | undefined
  ): { range: moment.Moment[]; isHoliday: boolean }[] => {
    if (Array.isArray(properties)) {
      const timeProperties = properties.map((v) => {
        const { from, to, isHoliday } = v;
        const range = [moment(from, format), moment(to, format)];
        return { range, isHoliday };
      });
      return timeProperties;
    }
    return [];
  };

  const onSubmit = ({
    timeProperties,
    time,
  }: {
    timeProperties: TimePropertiesForm[];
    time: string;
  }) => {
    const requestData: AccessTimeResquest = transformData(time, timeProperties);
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };

  const transformData = (
    time: string,
    timeProperties: TimePropertiesForm[]
  ): AccessTimeResquest => {
    const properties = timeProperties.map((property: TimePropertiesForm) => {
      const { range, isHoliday } = property;
      const m = {
        ...range.map((r) => r.format(format).toString().replace(':', '')),
      };
      const { 0: from, 1: to } = m;
      return { from, to, isHoliday };
    });
    return { id, time, properties };
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 14, offset: 6 },
    },
  };
  return (
    <Form
      form={form}
      data-testid="access-time-form"
      name="access-time-form"
      onFinish={onSubmit}
      autoComplete="off"
      {...formItemLayout}
    >
      <Form.Item
        name="time"
        label={t('name')}
        rules={[{ required: true }, { max: 50 }]}
      >
        <Input data-testid="name-input" placeholder={t('name')} />
      </Form.Item>
      <Form.List name="timeProperties">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? t('access-time') : ''}
                required={true}
                key={field.fieldKey}
                style={{ display: 'flex', margin: '0px' }}
              >
                <Space
                  key={field.key}
                  style={{ display: 'flex', margin: '0px' }}
                  align="baseline"
                >
                  <Form.Item
                    {...field}
                    name={[field.name, 'range']}
                    fieldKey={[field.fieldKey, 'range']}
                    rules={[
                      {
                        required: true,
                        message: t('errorMessage.required', {
                          labelName: t('access-time'),
                        }),
                      },
                    ]}
                  >
                    <RangePicker
                      format={format}
                      placeholder={[t('from'), t('to')]}
                      allowClear={false}
                      data-testid="time-picker"
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'isHoliday']}
                    fieldKey={[field.fieldKey, 'isHoliday']}
                    valuePropName="checked"
                  >
                    <Checkbox data-testid="holiday-checkbox">
                      {t('holiday')}
                    </Checkbox>
                  </Form.Item>
                  {fields.length > 1 ? (
                    <Tooltip title={t('button.delete')}>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Tooltip>
                  ) : null}
                </Space>
              </Form.Item>
            ))}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                data-testid="add-time-button"
              >
                {t('button.add')} {t('access-time')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
