import { ButtonSet } from '@components/ButtonSet';
import {
  FunctionRequest,
  FunctionResponseBody,
} from '@core/@models/FunctionModel';
import { ApiService } from '@core/services/api.service';
import { filterOption } from '@helpers/utils';
import { Form, Input, InputNumber, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import React, { useEffect, useMemo, useState } from 'react';
import routes from '../../@core/@routes/Routers';

export interface FunctionFormProps {
  id: number | null;
  handleCancel: () => void;
  forceFetchData: () => void;
}

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};

export const FunctionForm: React.FC<FunctionFormProps> = ({
  id,
  handleCancel,
  forceFetchData,
}) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const apiService = useMemo(() => new ApiService('/functions'), []);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    apiService
      .getDataById<string, FunctionResponseBody>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: FunctionResponseBody) =>
          form.setFieldsValue({ ...result }),
      });
  }, [id]);

  const onFinish = (data: FunctionRequest) => {
    const requestData = { ...data, id };
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        forceFetchData();
        handleCancel();
      },
    });
  };

  const getNewUrlList = (): { value: string; label: string }[] =>
    routes.map((r) => ({ value: r.path, label: r.path }));

  return (
    <Form
      {...layout}
      form={form}
      name="function-form"
      onFinish={onFinish}
      autoComplete="off"
      data-testid="function-form"
    >
      <Form.Item
        name="nameTh"
        label={t('nameTh')}
        rules={[{ required: true }, { max: 64 }]}
      >
        <Input data-testid="name-th-input" placeholder={t('nameTh')} />
      </Form.Item>
      <Form.Item
        name="nameEn"
        label={t('nameEn')}
        rules={[{ required: true }, { max: 64 }]}
      >
        <Input data-testid="name-en-input" placeholder={t('nameEn')} />
      </Form.Item>
      <Form.Item
        name="descriptionTh"
        label={t('descriptionTh')}
        rules={[{ max: 60 }]}
      >
        <Input
          data-testid="description-th-input"
          placeholder={t('descriptionTh')}
        />
      </Form.Item>
      <Form.Item
        name="descriptionEn"
        label={t('descriptionEn')}
        rules={[{ max: 60 }]}
      >
        <Input
          data-testid="description-en-input"
          placeholder={t('descriptionEn')}
        />
      </Form.Item>
      <Form.Item name="url" label={t('URL')} rules={[{ max: 100 }]}>
        <Input data-testid="url-input" placeholder={t('URL')} />
      </Form.Item>
      <Form.Item name="newUrl" label={t('newUrl')} rules={[{ max: 100 }]}>
        <Select
          showSearch
          placeholder={t('newUrl')}
          optionFilterProp="options"
          filterOption={filterOption}
          options={getNewUrlList()}
          data-testid="new-url-dropdown"
        />
      </Form.Item>
      <Form.Item name="apiServer" label="Server ID">
        <InputNumber
          placeholder="Server ID"
          style={{ width: '100%' }}
          max={999}
          min={0}
          data-testid="server-id-input"
        />
      </Form.Item>
      <Form.Item
        name="service"
        label={t('menuDisabled')}
        valuePropName="checked"
      >
        <Checkbox data-testid="service-checkbox" />
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
