import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input } from 'antd';
import { DomainResponse, DomainResquest } from '@core/@models/DomainModel';
import { useTranslation } from 'react-i18next';
import { ApiService } from '@core/services/api.service';
import { useDataContext } from '@contexts/DataContext';
import { ButtonSet } from '@components/ButtonSet';
import { finalize } from 'rxjs/operators';

interface DomainFormProps {
  id: number | null;
  handleCancel: () => void;
}

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
};

export const DomainForm: React.FC<DomainFormProps> = ({ id, handleCancel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const apiService = useMemo(() => new ApiService('/domains'), []);
  const { fetchData } = useDataContext();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!id) {
      return;
    }
    getDataById();
  }, [id]);

  const getDataById = () => {
    apiService
      .getDataById<string, DomainResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DomainResponse) => {
          setLoading(true);
          form.setFieldsValue({ ...result });
        },
      });
  };

  const onFinish = (data: DomainResquest) => {
    const requestData = { id, ...data };
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };

  return (
    <Form
      data-testid="domain-form"
      {...layout}
      form={form}
      name="domain-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        data-testid="domain"
        name="domain"
        label={t('menu:Domain')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <Input placeholder={t('menu:Domain')} />
      </Form.Item>
      <Form.Item
        data-testid="value"
        name="value"
        label={t('common:value')}
        rules={[{ required: true }, { max: 300 }]}
      >
        <Input placeholder={t('common:value')} />
      </Form.Item>
      <Form.Item
        data-testid="key"
        name="key"
        label={t('common:key')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <Input placeholder={t('common:key')} />
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
