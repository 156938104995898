import React, { useState, useEffect, useMemo } from 'react';
import {
  SystemConfigResponse,
  SystemConfigResquest,
} from '@core/@models/SystemConfigModel';
import { useTranslation } from 'react-i18next';
import { ApiService } from '@core/services/api.service';
import { useDataContext } from '@contexts/DataContext';
import { ButtonSet } from '@components/ButtonSet';
import { finalize } from 'rxjs/operators';
import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

export interface SystemConfigFormProps {
  id: number | null;
  handleCancel: () => void;
}

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
};

export const SystemConfigForm: React.FC<SystemConfigFormProps> = ({
  id,
  handleCancel,
}) => {
  const { t } = useTranslation(['common']);
  const apiService = useMemo(() => new ApiService('/system-configs'), []);
  const { fetchData, loading, setLoading } = useDataContext();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!id) return;
    getDataById();
  }, [id]);

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, SystemConfigResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: SystemConfigResponse) =>
          form.setFieldsValue({ ...result }),
      });
  };

  const onFinish = (data: SystemConfigResquest) => {
    setLoading(true);
    const requestData = { id, ...data };
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };

  return (
    <Form
      data-testid="system-config-form"
      {...layout}
      form={form}
      name="system-config-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="key"
        label={t('name')}
        rules={[{ required: true }, { max: 80 }]}
      >
        <Input data-testid="name-input" placeholder={t('name')} />
      </Form.Item>
      <Form.Item
        name="value"
        label={t('value')}
        rules={[{ required: true }, { max: 1500 }]}
      >
        <Input data-testid="value-input" placeholder={t('value')} />
      </Form.Item>
      <Form.Item name="description" label={t('description')}>
        <TextArea
          data-testid="description-text-area"
          showCount
          maxLength={80}
          placeholder={t('description')}
        />
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
