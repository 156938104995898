import { MenuDetail, SubMenuDetail } from '@core/@models/MenuModel';
import { Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SubMenuFormPage } from '../sub-menu/SubMenuFormPage';

const { Item } = Form;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export interface MenuFormProps {
  form: FormInstance;
  record: MenuDetail | null | undefined;
  onChange: (data: MenuDetail) => void;
}

const initialValues = {
  nameTh: '',
  nameEn: '',
  descriptionTh: '',
  descriptionEn: '',
  subMenus: [],
  id: null,
};

export const MenuForm: React.FC<MenuFormProps> = ({ form, ...props }) => {
  const { t } = useTranslation(['common']);
  const subMenuRef = useRef<SubMenuDetail[]>([]);
  const menuRef = useRef<MenuDetail>();

  const onChange = (menuDetail: MenuDetail, subMenus: SubMenuDetail[]) => {
    const menu = { ...menuDetail, ...{ subMenus } };
    props.onChange(menu as MenuDetail);
  };

  const onValuesChange = (_: MenuDetail, allValues: MenuDetail) => {
    menuRef.current = allValues;
    onChange(allValues, subMenuRef.current);
  };

  const onSubMenuChange = (data: SubMenuDetail[] | MenuDetail[]) => {
    subMenuRef.current = data as SubMenuDetail[];
    onChange(form.getFieldsValue(), subMenuRef.current);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        name="menu-form"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        data-testid="menu-form"
      >
        <Item
          name="nameTh"
          label={t('nameTh')}
          rules={[{ required: true }, { max: 40 }]}
        >
          <Input placeholder={t('nameTh')} data-testid="name-th-input" />
        </Item>
        <Item
          name="nameEn"
          label={t('nameEn')}
          rules={[{ required: true }, { max: 40 }]}
        >
          <Input placeholder={t('nameEn')} data-testid="name-en-input" />
        </Item>
        <Item
          name="descriptionTh"
          label={t('descriptionTh')}
          rules={[{ max: 40 }]}
        >
          <Input
            placeholder={t('descriptionTh')}
            data-testid="description-th-input"
          />
        </Item>
        <Item
          name="descriptionEn"
          label={t('descriptionEn')}
          rules={[{ max: 40 }]}
        >
          <Input
            placeholder={t('descriptionEn')}
            data-testid="description-en-input"
          />
        </Item>
        <Item name="id" hidden={true}></Item>
      </Form>
      <SubMenuFormPage
        onSubMenuChange={onSubMenuChange}
        subMenus={props.record?.subMenus}
      />
    </>
  );
};
