import { Label, Wrapper } from '@components/Search';
import { SearchButton } from '@components/SearchButton';
import { useSearchPagination } from '@helpers/use-search-pagination';
import { filterOption } from '@helpers/utils';
import { Card, Col, Input, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import routes from '../../@core/@routes/Routers';

export const FunctionSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    handleSearch,
    handleReset,
    handleInputChange,
    handleSelectChange,
    name,
    description,
    url,
    newUrl,
    serverId,
  } = useSearchPagination();

  const getNewUrlList = (): { value: string; label: string }[] =>
    routes.map((r) => ({ value: r.path, label: r.path }));

  return (
    <Card
      className="search-form"
      title={t('filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        <Col flex="100">
          <Wrapper>
            <Label>{t('name')}</Label>
            <Input
              placeholder={`${t('name')}...`}
              name="name"
              onChange={(e) => handleInputChange(e, 'name')}
              allowClear={true}
              value={name}
              data-testid="name-search-input"
            />
          </Wrapper>
        </Col>
        <Col flex="100">
          <Wrapper>
            <Label>{t('description')}</Label>
            <Input
              placeholder={`${t('description')}...`}
              name="description"
              onChange={(e) => handleInputChange(e, 'description')}
              allowClear={true}
              value={description}
              data-testid="description-search-input"
            />
          </Wrapper>
        </Col>
        <Col flex="100">
          <Wrapper>
            <Label>URL</Label>
            <Input
              placeholder={`${t('URL')}...`}
              name="URL"
              onChange={(e) => handleInputChange(e, 'url')}
              allowClear={true}
              value={url}
              data-testid="url-search-input"
            />
          </Wrapper>
        </Col>
        <Col flex="100">
          <Wrapper>
            <Label>{t('newUrl')}</Label>
            <Select
              style={{ width: '100%' }}
              allowClear
              showSearch
              placeholder={`${t('selectAll')}...`}
              optionFilterProp="options"
              filterOption={filterOption}
              onChange={(value) => handleSelectChange(value, 'newUrl')}
              options={getNewUrlList()}
              value={newUrl || undefined}
              data-testid="new-url-search-dropdown"
            />
          </Wrapper>
        </Col>
        <Col flex="100">
          <Wrapper>
            <Label>Server ID</Label>
            <ServerIdInput
              type="number"
              placeholder="Server ID..."
              name="serverId"
              onChange={(e) => handleInputChange(e, 'serverId')}
              value={serverId}
              data-testid="server-id-search-input"
            />
          </Wrapper>
        </Col>
      </Row>
    </Card>
  );
};

export const ServerIdInput = styled(Input)`
  margin-bottom: 2px;
  :focus-within {
    border-bottom: 2px solid #fcb034;
    margin-bottom: 0px;
  }
`;
