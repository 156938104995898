import { SearchProvider } from '@contexts/SearchProvider';
import { SecurityStatusConfigQueryParam } from '@core/@models/SecurityStatusCofigModel';
import React from 'react';
import { SecurityStatusConfigList } from './SecurityStatusConfigList';
import { SecurityStatusConfigSearch } from './SecurityStatusConfigSearch';

export const SecurityStatusConfig: React.FC = () => {
  const initialParam: SecurityStatusConfigQueryParam = {
    name: null,
    beginExpire: null,
    endExpire: null,
    securityType: null,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<SecurityStatusConfigQueryParam> initialParam={initialParam}>
      <SecurityStatusConfigSearch />
      <SecurityStatusConfigList />
    </SearchProvider>
  );
};
