import { FooterPage } from '@components/FooterPage';
import { AuthProvider } from '@contexts/AuthProvider';
import { ConfigProvider, Layout } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Headers } from './Headers';
import { SiderMenuCollapse } from './SideMenuCollapse';
import { SubHeader } from './SubHeader';
const { Content } = Layout;

export const DefaultLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const validateMessages = {
    required: t('common:errorMessage.required', {
      labelName: '${label}',
    }),
    string: {
      max: t('common:errorMessage.maxLength', {
        labelName: '${label}',
        max: '${max}',
      }),
    },
  };
  return (
    <AuthProvider>
      <ConfigProvider form={{ validateMessages }}>
        <Layout style={{ minHeight: '100vh' }} id="default-layout">
          <Headers />
          <Layout className="site-layout">
            <SiderMenuCollapse />
            <Layout style={{ padding: '0 24px 0 0' }}>
              <SubHeader></SubHeader>
              <Content
                className="site-layout-background"
                style={{ margin: '10px 16px 0', minHeight: 200 }}
              >
                {children}
              </Content>
              <FooterPage />
            </Layout>
          </Layout>
        </Layout>
      </ConfigProvider>
    </AuthProvider>
  );
};
