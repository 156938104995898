import { SearchProvider } from '@contexts/SearchProvider';
import { FunctionQueryParam } from '@core/@models/FunctionModel';
import { FunctionSearch } from 'pages/function/FunctionSearch';
import React from 'react';
import { FunctionList } from './FunctionList';

export const Function: React.FC = () => {
  const initialParam: FunctionQueryParam = {
    name: null,
    description: null,
    url: null,
    newUrl: null,
    serverId: null,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<FunctionQueryParam> initialParam={initialParam}>
      <FunctionSearch />
      <FunctionList />
    </SearchProvider>
  );
};
