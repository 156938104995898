import React, { useState } from 'react';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { SiderMenu } from './SiderMenu';

export const SiderMenuCollapse: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      <SiderMenu collapsed={collapsed} />
      {React.createElement(collapsed ? CloseOutlined : MenuOutlined, {
        className: 'trigger',
        onClick: () => setCollapsed(!collapsed),
      })}
    </>
  );
};
