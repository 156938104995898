import { LanguageService } from '@core/services/language.service';
import { Button } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const FooterPage: React.FC = () => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  return (
    <Footer id="footer">
      <div>
        {t('common:footer.copyRight')}
        <BtnLinkFooter
          type="link"
          size="small"
          onClick={() =>
            window.open(`https://www.set.or.th/${lang}/terms-and-conditions`)
          }
        >
          {t('common:footer.termAndCondition')}
        </BtnLinkFooter>
        |
        <BtnLinkFooter
          type="link"
          size="small"
          style={{ margin: '0' }}
          onClick={() =>
            window.open(`https://www.set.or.th/${lang}/privacy-notice`)
          }
        >
          {t('common:footer.privacy')}
        </BtnLinkFooter>
      </div>
    </Footer>
  );
};
const BtnLinkFooter = styled(Button)`
  font-size: 12px;
  font-weight: normal;
`;
