import dayjs from 'dayjs';
/**
 * Returns date in .
 *
 * @param {string} value The string in YYYY-MM-DD format.
 * @param {string} lang The language's date
 * @return {string} date string in 05 ส.ค. 2564 or 05 Aug 2021 corresponding with language param
 */
export const convertToDateShort = (value: string, lang = 'th'): string => {
  if (!value) return value;
  if (lang === 'th') {
    return dayjs(value).locale('th').format('DD MMM BBBB');
  }
  return dayjs(value).locale('en').format('DD MMM YYYY');
};
/**
 * Returns date in .
 *
 * @param {string} value The string in YYYY-MM-DD format.
 * @param {string} lang The language's date
 * @return {string} date string in 05 ส.ค. 2564 17:08 or 05 Aug 2021 17:08 corresponding with language param
 */
export const convertToDateTimeShort = (value: string, lang = 'th'): string => {
  if (!value) return value;
  if (lang === 'th') {
    return dayjs(value).locale('th').format('DD MMM BBBB HH:mm');
  }
  return dayjs(value).locale('en').format('DD MMM YYYY HH:mm');
};

/**
 * Returns string in .
 *
 * @param {string} value The string in DD/MM/YYYY format.
 * @return {string} date string in YYYY-MM-DD format
 */
export const convertToDateString = (value: string): string => {
  if (!value) return value;
  return value.split('/').reverse().join('-');
};
/**
 * Returns boolean.
 *
 * @param {string} input for search in option
 * @param {any} option object {label: string, value: string}
 * @return {boolean}
 */
export const filterOption = (input: string, option: any): boolean => {
  const label = option?.label?.toString() || '';
  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const convertByteToMB = (input: number): number => input / 1024 / 1024;

export const insertColon = (str: string): string => {
  return str.slice(0, 2) + ':' + str.slice(2);
};

export const backToSETPortal = (env?: string): void => {
  if (!env) return;
  let url = `https://${env}.setportal.set.or.th/setdd/welcome.do`;
  if (env === 'dev') {
    url = 'https://www.scpdev.set/setdd/welcome.do';
  } else if (env === 'prod') {
    url = 'https://www.setportal.set.or.th/setdd/welcome.do';
  }
  window.location.href = url;
};
