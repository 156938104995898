import { Search, TYPE } from '@components/Search';
import { DataContext } from '@contexts/DataContext';
import { useDataFetching } from '@helpers/use-data-fetching';
import React from 'react';
import { RoleList } from './RoleList';

export const Role: React.FC = () => {
  const dataFetching = useDataFetching('/roles');
  const fieldProperties = [
    { labelName: 'name', name: 'name', type: TYPE.INPUT },
    {
      labelName: 'grantable',
      name: 'grantable',
      type: TYPE.DROPDOWN,
      option: [
        { label: 'Y', value: true },
        { label: 'N', value: false },
      ],
    },
  ];
  return (
    <DataContext.Provider value={dataFetching}>
      <Search fieldProps={fieldProperties} />
      <RoleList />
    </DataContext.Provider>
  );
};
