import { Label, Wrapper } from '@components/Search';
import { SearchButton } from '@components/SearchButton';
import { RolesProperties } from '@core/@models/AttentionModel';
import { CompanyTypeOption } from '@core/@models/DcaConfigModel';
import { CustomService } from '@core/services/custom.service';
import { useSearchPagination } from '@helpers/use-search-pagination';
import { filterOption } from '@helpers/utils';

import { Card, Col, Input, Row, Select } from 'antd';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ManageRoleSupportSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    handleSearch,
    handleReset,
    loginName,
    email,
    roleId,
    handleInputChange,
    handleSelectChange,
  } = useSearchPagination();

  const [roles, setRoles] = useState<CompanyTypeOption[]>([]);
  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = () => {
    CustomService.getData<{ roles: RolesProperties[] }>(
      '/manage-role-support/regulator-profile/search-form'
    ).subscribe({
      next: ({ roles }: { roles: RolesProperties[] }) => {
        if (roles.length === 0) return;
        const options = roles.map((r) => ({
          value: `${r.id}`,
          label: r.name,
        }));
        setRoles(options);
      },
    });
  };
  return (
    <Card
      className="search-form"
      title={t('filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        <Col flex="100">
          <Wrapper>
            <Label>{t('loginName')}</Label>
            <Input
              placeholder={`${t('loginName')}...`}
              name="loginName"
              onChange={(e) => handleInputChange(e, 'loginName')}
              allowClear={true}
              value={loginName}
              data-testid="login-name"
            />
          </Wrapper>
        </Col>
        <Col flex="100">
          <Wrapper>
            <Label>{t('email')}</Label>
            <Input
              placeholder={`${t('email')}...`}
              name="email"
              onChange={(e) => handleInputChange(e, 'email')}
              allowClear={true}
              value={email}
              data-testid="email-search-input"
            />
          </Wrapper>
        </Col>

        <Col flex="100">
          <Wrapper>
            <Label>{t('role')}</Label>
            <Select
              allowClear
              showSearch
              options={roles}
              style={{ width: '100%' }}
              value={roleId || undefined}
              onChange={(value) => handleSelectChange(value, 'roleId')}
              placeholder={`${t('selectAll')}...`}
              optionFilterProp="options"
              filterOption={filterOption}
              data-testid="roleId-search-dropdown"
            />
          </Wrapper>
        </Col>
      </Row>
    </Card>
  );
};
