export interface MdfConfigResponse {
  id: number;
  nameTh: string;
  nameEn: string;
  value: string | undefined;
  systemType: string | undefined;
  configType: string;
  sequence: number;
  status: MdfConfigStatus;
}

export interface MdfConfigRequest {
  id: number | undefined;
  nameTh: string;
  nameEn: string;
  value: string;
  systemType: string | undefined;
  configType: string;
  sequence: number;
  status: MdfConfigStatus;
}

export enum MdfConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
