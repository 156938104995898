import { Search, TYPE } from '@components/Search';
import { useDataFetching } from '@helpers/use-data-fetching';
import React from 'react';
import { AccessTimeList } from './AccessTimeList';
import { DataContext } from '@contexts/DataContext';

export const AccessTime: React.FC = () => {
  const dataFetching = useDataFetching('/access-times');
  const fieldProperties = [
    {
      labelName: 'name',
      name: 'time',
      type: TYPE.INPUT,
    },
  ];
  return (
    <DataContext.Provider value={dataFetching}>
      <Search fieldProps={fieldProperties} />
      <AccessTimeList />
    </DataContext.Provider>
  );
};
