import { ButtonAdd } from '@components/ButtonAdd';
import { ModalCmp } from '@components/ModalCmp';
import { useDataContext } from '@contexts/DataContext';
import { RoleResponse } from '@core/@models/RoleModel';
import { DataResponse } from '@core/@models/Types';
import { LanguageService } from '@core/services/language.service';
import { useModalManagement } from '@helpers/use-modal-management';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleForm } from './RoleForm';
import { ApiService } from '@core/services/api.service';
import { DeleteButton } from '@components/DeleteButton';

export const RoleList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { data, loading, onSearch, fetchData } = useDataContext();
  const { visible, hideModal, showModal } = useModalManagement();
  const [id, setId] = useState<number | null>(null);
  const lang = LanguageService.getLanguage();
  const apiService = useMemo(() => new ApiService('/roles'), []);

  const handleClick = (reqId: number | null) => setId(reqId);

  const convertBooleanToString = (value: boolean): string =>
    value === true ? 'Y' : '';

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const getDeleteButtonView = (record: RoleResponse): React.ReactElement => (
    <DeleteButton id={record.id} onDelete={onDelete} />
  );

  const columns: ColumnsType<RoleResponse> = [
    {
      title: t('name'),
      dataIndex: 'name',
      width: '15%',
      sorter: {
        compare: (a, b) => (a.name || '').localeCompare(b.name || ''),
      },
    },
    {
      title: t('grantable'),
      dataIndex: 'grantable',
      render: convertBooleanToString,
      align: 'center',
      width: '8%',
    },
    {
      title: t('pending'),
      dataIndex: 'pending',
      render: convertBooleanToString,
      align: 'center',
      width: '7%',
    },
    {
      title: t('profileOnly'),
      dataIndex: 'profileOnly',
      render: convertBooleanToString,
      align: 'center',
      width: '7%',
    },
    {
      title: t('description'),
      dataIndex: 'descriptionTh',
      key: 'th',
      width: '30%',
      sorter: {
        compare: (a, b) =>
          (a.descriptionTh || '').localeCompare(b.descriptionTh || ''),
      },
    },
    {
      title: t('description'),
      dataIndex: 'descriptionEn',
      key: 'en',
      width: '30%',
      sorter: {
        compare: (a, b) =>
          (a.descriptionEn || '').localeCompare(b.descriptionEn || ''),
      },
    },
    {
      title: t('menu'),
      dataIndex: 'menuGroup',
      width: '12%',
    },
    {
      title: t('configScope'),
      dataIndex: 'scope',
      width: '15%',
    },
    {
      title: 'Action',
      width: '6%',
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const filterColumnByLang = React.useCallback(
    (): ColumnsType<RoleResponse> =>
      columns.filter((c) => c.key === lang || !c.key),
    [lang, columns]
  );

  const handleSelectRow = (record: RoleResponse) => {
    return {
      onClick: () => {
        handleClick(record.id);
        showModal();
      },
    };
  };

  return (
    <>
      <ButtonAdd handleClick={handleClick} showModal={showModal}></ButtonAdd>
      <Table
        data-testid="role-table"
        className="selectable-table"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        onRow={handleSelectRow}
        columns={filterColumnByLang()}
        dataSource={onSearch<DataResponse>(data, ['name', 'grantable'])}
        loading={loading}
        size="small"
        sortDirections={['ascend', 'descend', 'ascend']}
      ></Table>
      <ModalCmp
        visible={visible}
        title={id ? t('edit') : t('button.add')}
        handleCancel={hideModal}
        width={700}
      >
        <RoleForm id={id} handleCancel={hideModal}></RoleForm>
      </ModalCmp>
    </>
  );
};
