import { SearchProvider } from '@contexts/SearchProvider';
import { AttentionQueryParam } from '@core/@models/AttentionModel';
import React from 'react';
import { AttentionList } from './AttentionList';
import { AttentionSearch } from './AttentionSearch';

export const Attention: React.FC = () => {
  const initialParam: AttentionQueryParam = {
    begin: null,
    end: null,
    size: 15,
    page: 1,
    status: null,
  };

  return (
    <SearchProvider<AttentionQueryParam> initialParam={initialParam}>
      <AttentionSearch />
      <AttentionList />
    </SearchProvider>
  );
};
