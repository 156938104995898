import { ModalCmp } from '@components/ModalCmp';
import { useConfigDcaContext } from '@contexts/ConfigDcaContext';
import { DcaConfigResponseBody } from '@core/@models/DcaConfigModel';
import { DataResponseBody } from '@core/@models/Types';
import { LanguageService } from '@core/services/language.service';
import { useDataPagination } from '@helpers/use-data-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DcaConfigForm } from './DcaConfigForm';

export const DcaConfigList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const { companyTypes } = useConfigDcaContext();
  const [id, setId] = useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();

  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataPagination('/companies');

  const getDcaFlagView = (record: DataResponseBody): React.ReactElement => (
    <>{getDcaFlagStatus((record as DcaConfigResponseBody).dcaFlag)}</>
  );

  const getDcaFlagStatus = (dcaFlag?: boolean) => {
    if (dcaFlag) return 'Y';
    else if (dcaFlag === false) return 'N';
    return '';
  };

  const getCompanyTypeView = (record: DataResponseBody) => {
    const key = (record as DcaConfigResponseBody).companyType;
    return companyTypes.filter((c) => c.value === key)[0]?.label;
  };

  const filterColumnByLang = React.useCallback(
    (): ColumnsType<DataResponseBody> =>
      columns.filter((c) => c.key === lang || !c.key),
    [lang, companyTypes]
  );

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('code'),
      dataIndex: 'code',
      width: '15%',
    },
    {
      title: t('name'),
      dataIndex: 'nameTh',
      key: 'th',
      width: '45%',
    },
    {
      title: t('name'),
      dataIndex: 'nameEn',
      key: 'en',
      width: '45%',
    },
    {
      title: t('companyType'),
      width: '20%',
      render: getCompanyTypeView,
    },
    {
      title: 'DCA Flag',
      width: '10%',
      render: getDcaFlagView,
    },
  ];

  const handleSelectRow = (record: DataResponseBody) => {
    return {
      onClick: () => {
        setId(record.id);
        showModal();
      },
    };
  };

  return (
    <>
      <Table
        rowKey="id"
        style={{ marginTop: 10 }}
        className="selectable-table"
        pagination={pagination}
        onRow={handleSelectRow}
        columns={filterColumnByLang()}
        dataSource={data}
        loading={loading}
        size="small"
        data-testid="dca-config-table"
        onChange={handleTableChange}
      />
      <ModalCmp
        visible={visible}
        title={t('edit')}
        handleCancel={hideModal}
        width={700}
      >
        <DcaConfigForm
          id={id}
          handleCancel={hideModal}
          forceFetchData={() => fetchData()}
        />
      </ModalCmp>
    </>
  );
};
