import React from 'react';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { ConfirmModal } from '@components/ModalCmp';

export interface DeleteButtonProps {
  id: number;
  onDelete: (reqId: number) => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ id, onDelete }) => {
  const { t } = useTranslation('common');

  return (
    <Tooltip title={t('button.delete')}>
      <Button
        type="link"
        style={{ height: '20px' }}
        icon={<DeleteOutlined />}
        size="small"
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          event.stopPropagation();
          ConfirmModal({ onOk: () => onDelete(id) });
        }}
      />
    </Tooltip>
  );
};

export const Label = styled.label`
  font-weight: 500;
`;
