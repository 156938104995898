import { ButtonSet } from '@components/ButtonSet';
import { DcaConfigFormProps } from '@core/@models/DcaConfigModel';
import { ManageShldResponseBody } from '@core/@models/ManageShld';
import { SelectTypeOption } from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { filterOption } from '@helpers/utils';
import { Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

const { Item } = Form;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};
export const ManageShldForm: React.FC<DcaConfigFormProps> = ({
  id,
  handleCancel,
  forceFetchData,
}) => {
  const { t } = useTranslation(['config', 'common']);
  const shareHolderTypes: SelectTypeOption[] = [
    { value: 1, label: t('config:shareHolderType1') },
    { value: 2, label: t('config:shareHolderType2') },
    { value: 3, label: t('config:shareHolderType3') },
    { value: 4, label: t('config:shareHolderType4') },
    { value: 5, label: t('config:shareHolderType5') },
    { value: 6, label: t('config:shareHolderType6') },
    { value: 7, label: t('config:shareHolderType7') },
    { value: 8, label: t('config:shareHolderType8') },
  ];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const apiService = useMemo(() => new ApiService('/manage-shld-detail'), []);

  useEffect(() => {
    getForm();
  }, []);

  const getForm = () => {
    if (!id) return;
    setLoading(true);
    CustomService.getData<ManageShldResponseBody>(`/manage-shld-detail/${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (data: ManageShldResponseBody) => form.setFieldsValue(data),
      });
  };

  const onFinish = (formData: ManageShldResponseBody) => {
    setLoading(true);
    apiService
      .updateData(formData)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          forceFetchData();
          handleCancel();
        },
      });
  };

  return (
    <Form
      {...layout}
      data-testid="manage-shld-form"
      onFinish={onFinish}
      form={form}
    >
      <Item name="id" label="I_SHLD_DETAIL">
        <Input placeholder="I_SHLD_DETAIL" disabled />
      </Item>
      <Item name="holderCode" label="I_HOLDER">
        <Input placeholder="I_HOLDER" disabled />
      </Item>
      <Item name="holderTitle" label="N_TITLE">
        <Input placeholder="N_TITLE" disabled />
      </Item>
      <Item name="holderName" label="N_FIRST">
        <Input placeholder="holderName" disabled />
      </Item>
      <Item name="holderLastName" label="N_LAST">
        <Input placeholder="N_LAST" disabled />
      </Item>
      <Item name="shareAmount" label="Q_SHARE">
        <Input placeholder="Q_SHARE" disabled />
      </Item>
      <Item name="shldType" label="F_TYPE">
        <Select
          showSearch
          placeholder="F_TYPE"
          optionFilterProp="options"
          filterOption={filterOption}
          options={shareHolderTypes}
          data-testid="holder-type"
        />
      </Item>
      <Item name="shldId" label="I_SHLD">
        <Input placeholder="I_SHLD" disabled />
      </Item>
      <Item
        name="remark"
        label={t('common:remark')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <TextArea
          data-testid="remark"
          showCount
          maxLength={100}
          placeholder={t('common:remark')}
        />
      </Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
