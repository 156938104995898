import { SearchProvider } from '@contexts/SearchProvider';
import { ManageShldQueryParam } from '@core/@models/ManageShld';
import React from 'react';
import { ManageShldList } from './ManageShldList';
import { ManageShldSearch } from './ManageShldSerach';

export const ManageShld: React.FC = () => {
  const initialParam: ManageShldQueryParam = {
    id: null,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<ManageShldQueryParam> initialParam={initialParam}>
      <ManageShldSearch />
      <ManageShldList />
    </SearchProvider>
  );
};
