import { DeleteButton } from '@components/DeleteButton';
import { ModalCmp } from '@components/ModalCmp';
import { DataResponseBody } from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { LanguageService } from '@core/services/language.service';
import { useDataPagination } from '@helpers/use-data-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { convertToDateShort } from '@helpers/utils';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttentionViewRole } from '../attention/AttentionViewRole';
import { AttentionApproveForm } from './AttentionApproveForm';

export const AttentionApproveList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [id, setId] = useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();
  const apiService = useMemo(() => new ApiService('/attentions'), []);
  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataPagination('/attentions', { status: 'draft' });

  const lang = LanguageService.getLanguage();

  const handleClick = (reqId: number | null) => setId(reqId);

  const handleSelectRow = (record: DataResponseBody) => {
    handleClick(record.id);
    showModal();
  };

  const getRowLink = (
    text: string,
    record: DataResponseBody
  ): React.ReactElement => (
    <a onClick={() => handleSelectRow(record)}>{text}</a>
  );

  const getRoleView = (value: string[]): React.ReactElement => (
    <AttentionViewRole roles={value} />
  );

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const getDeleteButtonView = (record: DataResponseBody): React.ReactElement =>
    record.id ? <DeleteButton id={record.id} onDelete={onDelete} /> : <></>;

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('headline'),
      dataIndex: 'headLine',
      render: getRowLink,
    },
    {
      title: t('begin'),
      dataIndex: 'publishBegin',
      width: 125,
      render: (value: string): string => convertToDateShort(value, lang),
    },
    {
      title: t('end'),
      dataIndex: 'publishExpire',
      width: 125,
      render: (value: string): string => convertToDateShort(value, lang),
    },
    {
      title: t('role'),
      dataIndex: 'roles',
      width: 200,
      render: getRoleView,
    },
    {
      title: t('user'),
      dataIndex: 'user',
      width: 100,
    },
    {
      title: 'Action',
      width: 60,
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];
  return (
    <>
      <Table
        className="attention-approve-table selectable-table"
        pagination={pagination}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        size="small"
        data-testid="attention-approve-table"
      />
      <ModalCmp
        visible={visible}
        title={t('menu:Approve Attention')}
        handleCancel={hideModal}
        width={1000}
      >
        <AttentionApproveForm
          id={id}
          handleCancel={hideModal}
          forceFetchData={fetchData}
        ></AttentionApproveForm>
      </ModalCmp>
    </>
  );
};
