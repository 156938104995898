import { ButtonSet } from '@components/ButtonSet';
import { ModalCmp } from '@components/ModalCmp';
import { useConfigDcaContext } from '@contexts/ConfigDcaContext';
import { useDataContext } from '@contexts/DataContext';
import {
  ConfigCreatorProps,
  ConfigCreatorQueryParam,
  ConfigCreatorRequest,
  ConfigCreatorResponse,
} from '@core/@models/ConfigCreatorModel';
import { CompanyTypeOption } from '@core/@models/DcaConfigModel';
import { DataResponseBody } from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { LanguageService } from '@core/services/language.service';
import { useDataFetchingPagination } from '@helpers/use-data-fetching-pagination';
import { filterOption } from '@helpers/utils';
import { Button, Form, Input, Select, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};

type CompanyRequest = {
  id?: number;
  companyId?: number;
  nameTh: string;
  nameEn: string;
  companyType: string;
};

export const ConfigCreatorForm: React.FC<ConfigCreatorProps> = ({
  id,
  handleCancel,
}) => {
  const { t } = useTranslation(['common']);
  const { fetchData } = useDataContext();
  const [form] = Form.useForm();
  const { Item } = Form;
  const [loading, setLoading] = React.useState(false);
  const lang = LanguageService.getLanguage();
  const apiService = React.useMemo(
    () => new ApiService('/test-creator-configs'),
    []
  );
  const { companyTypes } = useConfigDcaContext();
  const [visible, setVisible] = useState(false);
  const [company, setCompany] = useState<CompanyRequest>({
    nameTh: '',
    nameEn: '',
    companyType: '',
  });
  const [data, setData] = useState<ConfigCreatorResponse>();

  React.useEffect(() => {
    if (!id) return;
    getDataById();
  }, [id]);

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, ConfigCreatorResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: ConfigCreatorResponse) => {
          const {
            companyNameTh: nameTh,
            companyNameEn: nameEn,
            companyId,
            companyType,
          } = result;
          setCompany({ nameEn, nameTh, companyId, companyType });
          form.setFieldsValue(result);
          setData(result);
        },
      });
  };

  const getCompanyTypeView = (value: string) => {
    return companyTypes.find((c) => c.value === value)?.label;
  };

  const getCompanyName = lang === 'th' ? company.nameTh : company.nameEn;

  const onChangeCompany = (companyRequest: CompanyRequest) => {
    setCompany(companyRequest);
  };

  const showModal = () => setVisible(true);

  const onFinish = (formData: ConfigCreatorRequest) => {
    const { userId } = data || { userId: 0 };
    const requestData = {
      userId,
      companyId: company?.companyId,
      remark: formData.remark,
      email: formData.email,
    };
    setLoading(true);
    const action$ = apiService.updateData(requestData);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };
  return (
    <>
      <Form
        {...layout}
        form={form}
        name="config-creator-form"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Item label={t('loginName')}>{data && data.loginName}</Item>
        <Item
          name="email"
          label={t('email')}
          rules={[{ required: true, type: 'email' }]}
        >
          {data && (
            <Input
              data-testid="email"
              placeholder={t('email')}
              value={data.email}
            />
          )}
        </Item>
        <Item label={t('companyType')}>
          {data && getCompanyTypeView(company.companyType)}
        </Item>
        <Item label={t('companyName')}>
          {getCompanyName}
          <Button
            type="dashed"
            onClick={showModal}
            style={{ marginLeft: '10px' }}
          >
            {t('edit')}
          </Button>
        </Item>
        <Item
          name="remark"
          label={t('remark')}
          rules={[{ required: true }, { max: 100 }]}
        >
          <TextArea
            data-testid="remark"
            showCount
            maxLength={100}
            placeholder={t('remark')}
          />
        </Item>
        <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
      </Form>
      <CompanySearch
        visible={visible}
        hideModal={() => setVisible(false)}
        onChangeCompany={onChangeCompany}
        companyTypes={companyTypes}
      />
    </>
  );
};

const CompanySearch: React.FC<{
  visible: boolean;
  hideModal: () => void;
  onChangeCompany: (companyRequest: CompanyRequest) => void;
  companyTypes: CompanyTypeOption[];
}> = ({ visible, hideModal, companyTypes, onChangeCompany }) => {
  const defaultParam = {
    company: null,
    companyType: null,
    size: 15,
    page: 1,
    sort: 'companyType,asc',
  };

  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const [queryParam, setQueryParam] = useState<ConfigCreatorQueryParam>(
    defaultParam
  );
  const {
    data,
    loading,
    pagination,
    handleTableChange,
  } = useDataFetchingPagination(queryParam, '/companies');

  React.useEffect(() => {
    if (!visible) return;
    setQueryParam(defaultParam);
  }, [visible]);

  const getCompanyTypeView = (value: string) => {
    return companyTypes.find((c) => c.value === value)?.label;
  };

  const handleCompany = (record: CompanyRequest) => {
    const { id: companyId, nameTh, nameEn, companyType } = record;
    onChangeCompany({ companyId, nameTh, nameEn, companyType });
    hideModal();
  };

  const clickButton = (record: CompanyRequest) => (
    <Button
      className="select-btn"
      size="small"
      type="link"
      onClick={() => handleCompany(record)}
    >
      {t('button.select')}
    </Button>
  );

  const onFinish = (val: { companyType: string; company: string }) => {
    setQueryParam({ ...queryParam, ...val });
  };

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: t('name'),
      dataIndex: 'nameTh',
      key: 'th',
      sorter: true,
    },
    {
      title: t('name'),
      dataIndex: 'nameEn',
      key: 'en',
      sorter: true,
    },
    {
      title: t('companyType'),
      dataIndex: 'companyType',
      render: getCompanyTypeView,
      sorter: true,
    },
    {
      title: 'Action',
      render: clickButton,
    },
  ];

  const filterColumnByLang = columns.filter((c) => c.key === lang || !c.key);

  return (
    <ModalCmp
      visible={visible}
      title={t('button.search')}
      handleCancel={hideModal}
      width={900}
    >
      <Form
        {...layout}
        name="config-creator-form"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="companyType" label={t('companyType')}>
          <Select
            showSearch
            placeholder={t('companyType')}
            optionFilterProp="options"
            filterOption={filterOption}
            options={companyTypes}
            data-testid="company-type"
          />
        </Form.Item>
        <Form.Item name="company" label={t('companyName')}>
          <Input placeholder={t('companyName')} />
        </Form.Item>
        <Form.Item noStyle>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit">
              {t('search')}
            </Button>
            <Button ghost size="middle" onClick={hideModal}>
              {t('close')}
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Table
        className="selectable-table"
        pagination={pagination}
        onChange={handleTableChange}
        columns={filterColumnByLang}
        size="small"
        rowKey="id"
        dataSource={data}
        loading={loading}
        style={{ margin: '10px 0px' }}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </ModalCmp>
  );
};
