import { FieldTimeOutlined } from '@ant-design/icons';
import { useAuthContext } from '@contexts/AuthProvider';
import { LanguageService } from '@core/services/language.service';
import { insertColon } from '@helpers/utils';
import { PageHeader, Tooltip } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BreadcrumbCmp } from './Breadcrumbs';

export const SubHeader: React.FC = () => {
  const { siderMenus } = useAuthContext();
  const lang = LanguageService.getLanguage();
  const nameKey = lang === 'th' ? 'nameTh' : 'nameEn';

  const location = useLocation<{ menuId: string; subMenuId: string }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const menuId = urlSearchParams.get('menuId') || '';
  const subMenuId = urlSearchParams.get('subMenuId') || '';

  const menu = siderMenus?.menus.find((m) => m.id === +menuId);
  const subMenu = menu?.subMenus.find((s) => s.id === +subMenuId);

  const accessTimes = subMenu?.times
    .map((time) => {
      return `[ ${insertColon(time.from)}-${insertColon(time.to)} ]`;
    })
    .join('');

  return (
    <>
      {menu && subMenu && (
        <Wrapper>
          <BreadcrumbCmp
            menu={{ id: menu.id, title: menu[nameKey] }}
            subMenu={{ id: subMenu.id, title: subMenu[nameKey] }}
          />
          <OperationTime>
            <Tooltip title="Operation Time">
              <TimeIcon />
            </Tooltip>
            <AccessTime>{accessTimes}</AccessTime>
          </OperationTime>
        </Wrapper>
      )}
      {subMenu && (
        <PageHeader title={subMenu[nameKey]} style={{ padding: '0px 15px' }} />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const TimeIcon = styled(FieldTimeOutlined)`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
`;
const AccessTime = styled.span`
  margin-left: 3px;
`;
const OperationTime = styled.div`
  margin: 15px;
  margin-left: auto;
  color: grey;
  font-size: 12px;
  display: flex;
`;
