import { MenuFunction, MenuResponse } from '@core/@models/MenuModel';
import React from 'react';

export interface MenuContextProps extends ConfigMenuFormSelect {
  filterOption?: (input: string, option: any) => boolean;
  fetchedData: MenuResponse | undefined;
  handleFetchedData: (data: MenuResponse) => void;
}

export type FunctionType = { label: string; value: number } & MenuFunction;

export interface ConfigMenuFormSelect {
  companyRoles: { label: string; value: string }[];
  times: { label: string; value: number }[];
  views: { label: string; value: string }[];
  functions: FunctionType[];
}

const ConfigMenuContext = React.createContext<MenuContextProps | undefined>(
  undefined
);

function useConfigMenuContext(): MenuContextProps {
  const configMenuContext = React.useContext(ConfigMenuContext);
  if (!configMenuContext) {
    throw new Error(
      'useConfigMenuContext must be used within the DataContext.Provider'
    );
  }
  return configMenuContext;
}

export { useConfigMenuContext, ConfigMenuContext };
