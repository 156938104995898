import { ButtonSet } from '@components/ButtonSet';
import { useDataContext } from '@contexts/DataContext';
import { ConfigRoleForm, RoleResponse } from '@core/@models/RoleModel';
import { CustomFormProps } from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { filterOption } from '@helpers/utils';
import { Checkbox, Form, Input, InputNumber, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

const { Item } = Form;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};
const urlConfigData = '/roles/config-form';
const urlMainComponent = '/roles';

export const RoleForm: React.FC<CustomFormProps> = ({ id, handleCancel }) => {
  const [configData, setConfigData] = useState<{
    data: {
      menuGroups: { label: string; value: number }[];
      downloadScopes: { label: string; value: string }[];
      marketScopes: { label: string; value: string }[];
    };
    fetching: boolean;
  }>();
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const apiService = useMemo(() => new ApiService(urlMainComponent), []);
  const { fetchData, loading, setLoading } = useDataContext();

  useEffect(() => {
    if (!id) {
      initialFormValue();
      return;
    }
    getDataById();
  }, [id]);

  useEffect(() => {
    getConfigGroup();
  }, []);

  const initialFormValue = () => {
    form.setFieldsValue({
      assignScope: false,
      grantable: false,
      descriptionEn: '',
      descriptionTh: '',
      menu: '',
      name: '',
      marketScopes: [],
      downloadScopes: [],
      pending: false,
      profileOnly: false,
    });
  };

  const getDataById = () => {
    apiService
      .getDataById<string, RoleResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: RoleResponse) => {
          setLoading(true);
          form.setFieldsValue(result);
        },
      });
  };

  const getConfigGroup = () => {
    setConfigData({
      data: { menuGroups: [], marketScopes: [], downloadScopes: [] },
      fetching: true,
    });
    CustomService.getData<ConfigRoleForm>(urlConfigData).subscribe({
      next: (result: ConfigRoleForm) => {
        if (!result) return;

        const menuGroups = result.menuGroups.map((r) => ({
          value: r.id,
          label: `(${r.name}) ${r.descriptionTh}`,
        }));
        const downloadScopes = result.downloadScopes.map((r) => ({
          value: r,
          label: r,
        }));
        const marketScopes = result.marketScopes.map((r) => ({
          value: r,
          label: r,
        }));
        const data = { menuGroups, downloadScopes, marketScopes };
        setConfigData({ data, fetching: false });
      },
      error: () =>
        setConfigData({
          data: { menuGroups: [], marketScopes: [], downloadScopes: [] },
          fetching: false,
        }),
    });
  };

  const onFinish = (data: RoleResponse) => {
    const requestData = { ...data, id };
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };

  return (
    <Form {...layout} data-testid="role-form" onFinish={onFinish} form={form}>
      <Item
        name="name"
        label={t('name')}
        rules={[{ required: true }, { max: 30 }]}
      >
        <Input placeholder={t('name')} data-testid="name-input" />
      </Item>
      <Item
        name="descriptionTh"
        label={t('descriptionTh')}
        rules={[{ max: 200 }]}
      >
        <Input
          placeholder={t('descriptionTh')}
          data-testid="description-th-input"
        />
      </Item>
      <Item
        name="descriptionEn"
        label={t('descriptionEn')}
        rules={[{ max: 200 }]}
      >
        <Input
          placeholder={t('descriptionEn')}
          data-testid="description-en-input"
        />
      </Item>
      <Item name="roleGroup" label="I_GROUP">
        <InputNumber
          data-testid="group-input"
          placeholder="I_GROUP"
          style={{ width: '100px' }}
        />
      </Item>
      <Item
        name="menuGroupId"
        label={t('menu')}
        rules={[{ type: 'number', required: true }]}
      >
        <Select
          showSearch
          placeholder={t('menu')}
          optionFilterProp="options"
          filterOption={filterOption}
          options={configData?.data.menuGroups}
          data-testid="menu-dropdown"
        />
      </Item>
      <Item name="marketScopes" label="Market Scopes">
        <Select
          mode="multiple"
          placeholder="Market Scopes"
          allowClear
          showSearch
          optionFilterProp="options"
          filterOption={filterOption}
          options={configData?.data.marketScopes}
          data-testid="market-scope-dropdown"
        />
      </Item>
      <Item name="downloadScopes" label="Download Scopes">
        <Select
          mode="multiple"
          placeholder="Download Scopes"
          allowClear
          showSearch
          optionFilterProp="options"
          filterOption={filterOption}
          options={configData?.data.downloadScopes}
          data-testid="download-scope-dropdown"
        />
      </Item>
      <Item
        name="assignScope"
        label="Assign Default Scopes"
        valuePropName="checked"
      >
        <Checkbox data-testid="assign-scope-checkbox" />
      </Item>
      <Item
        name="grantable"
        label={t('compAdminGrantable')}
        valuePropName="checked"
      >
        <Checkbox data-testid="grantable-checkbox" />
      </Item>
      <Item name="pending" label={t('pending')} valuePropName="checked">
        <Checkbox data-testid="pending-checkbox" />
      </Item>
      <Item name="profileOnly" label={t('profileOnly')} valuePropName="checked">
        <Checkbox data-testid="profile-only-checkbox" />
      </Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
