import { ModalCmp } from '@components/ModalCmp';
import { useConfigDcaContext } from '@contexts/ConfigDcaContext';
import { useDataContext } from '@contexts/DataContext';
import { ConfigCreatorResponse } from '@core/@models/ConfigCreatorModel';
import { DataResponse } from '@core/@models/Types';
import { LanguageService } from '@core/services/language.service';
import { useModalManagement } from '@helpers/use-modal-management';
import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigCreatorForm } from './ConfigCreatorForm';

export const ConfigCreatorList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const [id, setId] = React.useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();
  const { data, loading, onSearch, onSetQuery } = useDataContext();
  const { companyTypes } = useConfigDcaContext();

  useEffect(() => {
    onSetQuery({ companyNameTh: '', companyNameEn: '' });
  }, [lang]);

  const filterColumnByLang = React.useCallback(
    (): ColumnsType<ConfigCreatorResponse> =>
      columns.filter((c) => c.key === lang || !c.key),
    [lang, companyTypes]
  );

  const getCompanyTypeView = (value: string) => {
    return companyTypes.find((c) => c.value === value)?.label;
  };

  const columns: ColumnsType<ConfigCreatorResponse> = [
    {
      title: t('loginName'),
      dataIndex: 'loginName',
      sorter: {
        compare: (a, b) => a.loginName.localeCompare(b.loginName),
      },
    },
    {
      title: t('email'),
      dataIndex: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
    },
    {
      title: t('companyType'),
      dataIndex: 'companyType',
      sorter: {
        compare: (a, b) => a.companyType.localeCompare(b.companyType),
      },
      width: '20%',
      render: getCompanyTypeView,
    },
    {
      title: t('companyName'),
      dataIndex: 'companyNameTh',
      key: 'th',
      sorter: {
        compare: (a, b) => a.companyNameTh.localeCompare(b.companyNameTh),
      },
    },
    {
      title: t('companyName'),
      dataIndex: 'companyNameEn',
      key: 'en',
      sorter: {
        compare: (a, b) => a.companyNameEn.localeCompare(b.companyNameEn),
      },
    },
  ];
  const handleClick = (reqId: number | null) => setId(reqId);
  const handleSelectRow = (record: ConfigCreatorResponse) => {
    return {
      onClick: () => {
        handleClick(record.userId);
        showModal();
      },
    };
  };

  return (
    <>
      <Table
        className="selectable-table"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        onRow={handleSelectRow}
        columns={filterColumnByLang()}
        size="small"
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey="id"
        dataSource={onSearch<DataResponse>(data, ['loginName', 'companyType'])}
        loading={loading}
        style={{ margin: '10px 0px' }}
      />
      <ModalCmp
        visible={visible}
        title={t('edit')}
        handleCancel={hideModal}
        width={700}
      >
        <ConfigCreatorForm id={id} handleCancel={hideModal} />
      </ModalCmp>
    </>
  );
};
