import { MenuDetail } from '@core/@models/MenuModel';
import React from 'react';
import { MenuTable } from '../MenuTable';
import { MenuFormModal } from './MenuFormModal';

interface MenuFormPageProps {
  onChange: (data: MenuDetail[]) => void;
  menus: MenuDetail[] | undefined;
}
export const MenuFormPage: React.FC<MenuFormPageProps> = (props) => {
  const [record, setRecord] = React.useState<{
    record?: MenuDetail;
    index?: number;
  }>({});

  React.useEffect(() => {
    setMenus(props.menus || []);
  }, [props.menus]);

  const [menus, setMenus] = React.useState<MenuDetail[]>([]);

  React.useEffect(() => {
    props.onChange(menus);
  }, [menus]);

  const handleRecord = (menuDetail: MenuDetail, index: number) =>
    setRecord({ record: menuDetail, index });

  const onOk = (data: MenuDetail, index?: number) => {
    if (data === undefined) return;
    if (index === undefined) {
      setMenus([
        ...menus.map((m, i) => ({ ...m, index: i })),
        { ...data, index: menus.length },
      ]);
    } else {
      menus[index] = data;
      setMenus([...menus.map((m, i) => ({ ...m, index: i }))]);
    }
    setRecord({});
  };

  const onCancel = () => {
    setRecord({});
  };

  const handleSortData = (records: MenuDetail[]) => {
    setMenus(records);
    props.onChange(records);
  };

  return (
    <>
      <MenuFormModal
        record={record?.record}
        index={record?.index}
        onOk={onOk}
        onCancel={onCancel}
      />
      <MenuTable
        data={menus}
        pageName="menu"
        handleRecord={handleRecord}
        handleSortData={handleSortData}
      />
    </>
  );
};
