import React, { useEffect, useMemo } from 'react';
import { Form, Input, Select } from 'antd';
import { finalize } from 'rxjs/operators';
import { filterOption } from '@helpers/utils';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { ButtonSet } from '@components/ButtonSet';
import { useDataContext } from '@contexts/DataContext';
import { ApiService } from '@core/services/api.service';
import {
  MdfConfigRequest,
  MdfConfigResponse,
  MdfConfigStatus,
} from '@core/@models/MdfConfigModel';

export interface MdfConfigFormProps {
  id: number | null;
  handleCancel: () => void;
}

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
};

export const MdfConfigForm: React.FC<MdfConfigFormProps> = ({
  id,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);
  const { fetchData, setLoading, loading } = useDataContext();
  const apiService = useMemo(() => new ApiService('/mdf-configs'), []);

  useEffect(() => {
    if (!id) return;
    getDataById();
  }, [id]);

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, MdfConfigResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: MdfConfigResponse) => form.setFieldsValue({ ...result }),
      });
  };

  const onFinish = (data: MdfConfigRequest) => {
    setLoading(true);
    const requestData = { ...data, id };
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };

  const statusOptions = [MdfConfigStatus.ACTIVE, MdfConfigStatus.INACTIVE].map(
    (status) => {
      return {
        label: status,
        value: status,
      };
    }
  );

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      data-testid="mdf-config-form"
    >
      <Form.Item
        name="nameTh"
        label={t('nameTh')}
        rules={[{ required: true }, { max: 1000 }]}
      >
        <TextArea data-testid="name-th-text-area" placeholder={t('nameTh')} />
      </Form.Item>
      <Form.Item
        name="nameEn"
        label={t('nameEn')}
        rules={[{ required: true }, { max: 1000 }]}
      >
        <TextArea data-testid="name-en-text-area" placeholder={t('nameEn')} />
      </Form.Item>
      <Form.Item
        name="value"
        label={t('value')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <TextArea data-testid="value-text-area" placeholder={t('value')} />
      </Form.Item>
      <Form.Item
        name="systemType"
        label={t('System Type')}
        rules={[{ max: 1 }]}
      >
        <Input data-testid="system-type-input" placeholder={t('System Type')} />
      </Form.Item>
      <Form.Item
        name="configType"
        label={t('Config Type')}
        rules={[{ required: true }, { max: 20 }]}
      >
        <Input data-testid="config-type-input" placeholder={t('Config Type')} />
      </Form.Item>
      <Form.Item
        name="sequence"
        label={t('Sequence')}
        rules={[{ required: true }]}
      >
        <Input
          type="number"
          data-testid="sequence-input"
          placeholder={t('Sequence')}
        />
      </Form.Item>
      <Form.Item name="status" label={t('status')} rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder={t('status')}
          optionFilterProp="options"
          filterOption={filterOption}
          options={statusOptions}
          data-testid="status-dropdown"
        />
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
