import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { SystemConfigResponse } from '@core/@models/SystemConfigModel';
import { SystemConfigForm } from './SystemConfigForm';
import { ModalCmp } from '@components/ModalCmp';
import { useTranslation } from 'react-i18next';
import { DataResponse } from '@core/@models/Types';
import { useDataContext } from '@contexts/DataContext';
import { useModalManagement } from '@helpers/use-modal-management';
import { ButtonAdd } from '@components/ButtonAdd';
import { ApiService } from '@core/services/api.service';
import { DeleteButton } from '@components/DeleteButton';
import { CustomTable } from '@components/CustomTable';

export const SystemConfigList: React.FC = () => {
  const [id, setId] = useState<number | null>(null);
  const { t } = useTranslation(['common']);
  const { data, loading, onSearch, fetchData } = useDataContext();
  const { visible, hideModal, showModal } = useModalManagement();
  const apiService = useMemo(() => new ApiService('/system-configs'), []);
  const handleClick = (reqId: number | null) => setId(reqId);

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const getDeleteButtonView = (
    record: SystemConfigResponse
  ): React.ReactElement => <DeleteButton id={record.id} onDelete={onDelete} />;

  const columns: ColumnsType<SystemConfigResponse> = [
    {
      title: t('name'),
      dataIndex: 'key',
      width: '45%',
      sorter: {
        compare: (a, b) => (a.key || '').localeCompare(b.key || ''),
      },
    },
    {
      title: t('value'),
      dataIndex: 'value',
      width: '45%',
      sorter: {
        compare: (a, b) => (a.value || '').localeCompare(b.value || ''),
      },
    },
    {
      title: 'Action',
      width: '10%',
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const handleSelectRow = (record: SystemConfigResponse) => {
    return {
      onClick: () => {
        handleClick(record.id);
        showModal();
      },
    };
  };

  return (
    <>
      <ButtonAdd handleClick={handleClick} showModal={showModal} />
      <CustomTable<SystemConfigResponse>
        data-testid="system-config-table"
        onRow={handleSelectRow}
        columns={columns}
        dataSource={onSearch<DataResponse>(data, ['key'])}
        loading={loading}
      />
      <ModalCmp
        visible={visible}
        title={id ? t('edit') : t('button.add')}
        handleCancel={hideModal}
        width={700}
      >
        <SystemConfigForm id={id} handleCancel={hideModal}></SystemConfigForm>
      </ModalCmp>
    </>
  );
};
