import { ModalCmp } from '@components/ModalCmp';
import { ManageShldResponseBody } from '@core/@models/ManageShld';
import { DataResponseBody } from '@core/@models/Types';
import { useDataPagination } from '@helpers/use-data-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ManageShldForm } from './ManageShldForm';

export const ManageShldList: React.FC = () => {
  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataPagination('/manage-shld-detail');
  const { t } = useTranslation(['common']);
  const [id, setId] = useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();
  const columns: ColumnsType<DataResponseBody> = [
    {
      title: 'I_SHLD_DETAIL',
      dataIndex: 'id',
    },
    {
      title: 'I_HOLDER',
      dataIndex: 'holderCode',
    },
    {
      title: 'N_TITLE',
      dataIndex: 'holderTitle',
    },
    {
      title: 'N_FIRST',
      dataIndex: 'holderName',
    },
    {
      title: 'N_LAST',
      dataIndex: 'holderLastName',
    },
    {
      title: 'Q_SHARE',
      dataIndex: 'shareAmount',
    },
    {
      title: 'F_TYPE',
      dataIndex: 'shldType',
    },
    {
      title: 'I_SHLD',
      dataIndex: 'shldId',
    },
  ];

  const handleSelectRow = (record: DataResponseBody) => {
    return {
      onClick: () => {
        const { id } = record as ManageShldResponseBody;
        setId(id);
        showModal();
      },
    };
  };

  return (
    <>
      <Table
        data-testid="manage-role-support-table"
        className="selectable-table"
        pagination={pagination}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        onRow={handleSelectRow}
        style={{ marginTop: '10px' }}
      />
      <ModalCmp
        visible={visible}
        title={t('edit')}
        handleCancel={hideModal}
        width={1000}
      >
        <ManageShldForm
          id={id}
          handleCancel={hideModal}
          forceFetchData={() => fetchData()}
        />
      </ModalCmp>
    </>
  );
};
