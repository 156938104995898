import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import { useConfigMenuContext } from '@contexts/ConfigMenuContext';
import { MenuDetail, SubMenuDetail } from '@core/@models/MenuModel';
import { Button, Space, Table, Tooltip } from 'antd';
import { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import arrayMove from 'array-move';
import React, { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

interface MenuTableProps<T> {
  handleRecord: (record: T, index: number) => void;
  data: T[];
  handleSortData: (data: T[]) => void;
  tableProps?: TableProps<T>;
  pageName: string;
}

export const MenuTable = <T extends SubMenuDetail | MenuDetail>({
  handleRecord,
  ...props
}: MenuTableProps<T>): React.ReactElement => {
  const [dataSource, setDataSource] = useState<T[]>([]);
  const { times } = useConfigMenuContext();
  const { t } = useTranslation(['common']);
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    addIndexData();
  }, [props.data]);

  const addIndexData = () => {
    const result: T[] = props.data.map((subMenu: T, index: number) => ({
      index,
      ...subMenu,
    }));
    setDataSource(result);
  };

  const showDescription = (text: string, record: T): React.ReactElement => (
    <>
      {text} {record.descriptionEn && `(${record.descriptionEn})`}
    </>
  );

  const showName = (
    text: string,
    record: T,
    index: number
  ): React.ReactElement => {
    const isSubMenu = 'timeId' in record;
    const content = isSubMenu
      ? `${index + 1}. ${text} (${record.nameEn})`
      : `${text} (${record.nameEn})`;
    return <>{content}</>;
  };

  const addDragSymbol = (_: string, record: T): React.ReactElement => (
    <DragableHandler record={record} />
  );

  const convertBoolean = (text: boolean | undefined): string => {
    if (text === undefined) return '';
    return text ? 'yes' : 'no';
  };

  const showTimeName = (value: number): string => {
    if (!value) return '';
    const result = times.find((time) => time.value === value)?.label;
    return result || `${value}`;
  };

  const handleDelete = (record: T) => {
    const deletedData = [...dataSource];
    const index = deletedData.findIndex((item) => record.index === item.index);

    deletedData.splice(index, 1);
    setDataSource([...deletedData]);
    props.handleSortData([...deletedData]);
  };

  const setAction = (_: number, record: T, index: number) => {
    const isSubMenu = 'timeId' in record;
    if (isSubMenu && props.pageName === 'menu') return <></>;
    return (
      <Space align="end">
        <Tooltip title={t('edit')}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              handleRecord(record, index);
            }}
          ></Button>
        </Tooltip>
        <Tooltip title={t('button.delete')}>
          <Button
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => {
              handleDelete(record);
            }}
          ></Button>
        </Tooltip>
      </Space>
    );
  };

  const columns: ColumnsType<T> = [
    {
      dataIndex: 'nameTh',
      width: 50,
      render: addDragSymbol,
    },
    {
      title: t('name'),
      dataIndex: 'nameTh',
      width: 250,
      render: showName,
    },
    {
      title: t('description'),
      dataIndex: 'descriptionTh',
      width: 200,
      render: showDescription,
    },
    {
      title: t('time'),
      dataIndex: 'timeId',
      width: 100,
      render: showTimeName,
    },
    {
      title: t('preview'),
      dataIndex: 'view',
      width: 90,
    },
    {
      title: t('confirm'),
      dataIndex: 'confirm',
      width: 70,
      render: convertBoolean,
    },
    {
      title: t('read-only'),
      dataIndex: 'readOnly',
      width: 90,
      render: convertBoolean,
    },
    {
      title: 'Action',
      width: 60,
      dataIndex: 'id',
      align: 'center',
      className: 'last-column',
      render: setAction,
    },
  ];

  const DragableHandler = SortableHandle(({ record }: { record: T }) => {
    const isSubMenu = 'timeId' in record;
    return (
      <span style={{ marginLeft: '5px' }}>
        {((!isSubMenu && !expandedRowKeys.length) ||
          props.pageName !== 'menu') && (
          <Tooltip title={t('button.move')}>
            <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
          </Tooltip>
        )}
      </span>
    );
  });
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const sortedData = arrayMove([...dataSource], oldIndex, newIndex).filter(
        (el) => !!el
      );
      setDataSource(sortedData);
      props.handleSortData(sortedData);
    }
  };

  const SortableItem = SortableElement((trProps: any) => <tr {...trProps} />);
  const SortableContainers = SortableContainer((tbodyProps: any) => (
    <tbody {...tbodyProps} />
  ));

  const DraggableContainer = (sortableContainersProps: any) => (
    <SortableContainers
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...sortableContainersProps}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handleOnExpandIcon = (val: any): React.ReactNode => {
    const { expanded, onExpand, record } = val;
    const isSubMenu = 'timeId' in record;
    if (isSubMenu) return null;
    return expanded ? (
      <MinusCircleTwoTone
        twoToneColor="#FCB034"
        onClick={(e) => {
          setExpandedRowKeys(expandedRowKeys.filter((k) => record.index !== k));
          onExpand(record, e);
        }}
      />
    ) : (
      <PlusCircleTwoTone
        twoToneColor="#FCB034"
        onClick={(e) => {
          setExpandedRowKeys([...expandedRowKeys, record.index]);
          onExpand(record, e);
        }}
      />
    );
  };

  return (
    <Table
      data-testid="add-menu-table"
      className="selectable-table"
      size="small"
      bordered
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      rowKey="index"
      childrenColumnName="subMenus"
      expandable={{
        indentSize: 20,
        defaultExpandAllRows: true,
        expandIcon: handleOnExpandIcon,
      }}
      components={
        !expandedRowKeys.length
          ? {
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }
          : undefined
      }
      {...props.tableProps}
    />
  );
};
