import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ModalCmp } from '@components/ModalCmp';
import { DomainResponse } from '@core/@models/DomainModel';
import { DomainForm } from './DomainForm';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '@contexts/DataContext';
import { DataResponse } from '@core/@models/Types';
import { useModalManagement } from '@helpers/use-modal-management';
import { ButtonAdd } from '@components/ButtonAdd';
import { ApiService } from '@core/services/api.service';
import { DeleteButton } from '@components/DeleteButton';

export const DomainList: React.FC = () => {
  const [id, setId] = useState<number | null>(null);
  const { t } = useTranslation();
  const { data, loading, onSearch, fetchData } = useDataContext();
  const { visible, hideModal, showModal } = useModalManagement();
  const handleClick = (reqId: number | null) => setId(reqId);
  const apiService = useMemo(() => new ApiService('/domains'), []);

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const getDeleteButtonView = (record: DomainResponse): React.ReactElement => (
    <DeleteButton id={record.id} onDelete={onDelete} />
  );

  const columns: ColumnsType<DomainResponse> = [
    {
      title: t('menu:Domain'),
      dataIndex: 'domain',
      width: 150,
      sorter: {
        compare: (a, b) => (a.domain || '').localeCompare(b.domain || ''),
      },
    },
    {
      title: t('common:value'),
      dataIndex: 'value',
      width: 200,
      sorter: {
        compare: (a, b) => (a.value || '').localeCompare(b.value || ''),
      },
    },
    {
      title: t('common:key'),
      dataIndex: 'key',
      width: 100,
      sorter: {
        compare: (a, b) => (a.key || '').localeCompare(b.key || ''),
      },
    },
    {
      title: 'Action',
      width: 30,
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const handleSelectRow = (record: DomainResponse) => {
    return {
      onClick: () => {
        handleClick(record.id);
        showModal();
      },
    };
  };

  return (
    <>
      <ButtonAdd handleClick={handleClick} showModal={showModal}></ButtonAdd>
      <Table
        className="selectable-table"
        data-testid="domainTable"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('common:totalItem', { total }),
        }}
        onRow={handleSelectRow}
        columns={columns}
        dataSource={onSearch<DataResponse>(data, ['domain', 'key'])}
        loading={loading}
        rowKey="id"
        size="small"
        sortDirections={['ascend', 'descend', 'ascend']}
      ></Table>
      <ModalCmp
        visible={visible}
        title={id ? t('common:edit') : t('common:button.add')}
        handleCancel={hideModal}
        width={700}
      >
        <DomainForm id={id} handleCancel={hideModal}></DomainForm>
      </ModalCmp>
    </>
  );
};
