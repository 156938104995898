import { FooterPage } from '@components/FooterPage';
import { AuthProvider, useAuthContext } from '@contexts/AuthProvider';
import { Layout, Spin } from 'antd';
import React from 'react';
import { Headers } from './Headers';
import { SiderMenuCollapse } from './SideMenuCollapse';
const { Content } = Layout;

export const HomeLayout: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <Layout style={{ minHeight: '100vh' }}>
        <HomeLoading>{children}</HomeLoading>
      </Layout>
    </AuthProvider>
  );
};

const HomeLoading: React.FC = ({ children }) => {
  const { loading } = useAuthContext();
  return (
    <Spin size="large" spinning={loading}>
      <Headers />
      <Layout className="site-layout" id="home-layout">
        <SiderMenuCollapse />
        <Layout style={{ padding: '0 24px 0 0' }}>
          <Content
            className="site-layout-background"
            style={{ margin: '10px 16px 0', minHeight: 200 }}
          >
            {children}
          </Content>
          <FooterPage />
        </Layout>
      </Layout>
    </Spin>
  );
};
