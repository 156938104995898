import React from 'react';

type LayoutProps = {
  layout: any;
  isPublic?: boolean;
};
export const Layouts: React.FC<LayoutProps> = ({
  layout: Component,
  isPublic,
  children,
}) => {
  if (isPublic) return <Component>{children}</Component>;
  return <>{children}</>;
};
