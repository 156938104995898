import React from 'react';
import { AttentionView } from './attention-view';

export const Home: React.FC = () => {
  return (
    <div style={{ marginTop: '24px' }}>
      <AttentionView></AttentionView>
    </div>
  );
};
