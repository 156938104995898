import React, { useMemo, useState, useEffect } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { ModalCmp } from '@components/ModalCmp';
import { MdfConfigForm } from './MdfConfigForm';
import { ButtonAdd } from '@components/ButtonAdd';
import { DataResponse } from '@core/@models/Types';
import { useDataContext } from '@contexts/DataContext';
import { ApiService } from '@core/services/api.service';
import { DeleteButton } from '@components/DeleteButton';
import { MdfConfigResponse } from '@core/@models/MdfConfigModel';
import { LanguageService } from '@core/services/language.service';
import { useModalManagement } from '@helpers/use-modal-management';

export const MdfConfigList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const [id, setId] = useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();
  const apiService = useMemo(() => new ApiService('/mdf-configs'), []);
  const { data, loading, onSearch, fetchData, onSetQuery } = useDataContext();

  useEffect(() => {
    onSetQuery({ nameTh: '', nameEn: '' });
  }, [lang]);

  const handleClick = (reqId: number | null) => setId(reqId);

  const onDelete = (reqId: number) => {
    apiService.deleteData<number | null>(reqId).subscribe({
      next: () => fetchData(),
    });
  };

  const getDeleteButtonView = (
    record: MdfConfigResponse
  ): React.ReactElement => <DeleteButton id={record.id} onDelete={onDelete} />;

  const filterColumnByLang = React.useCallback(
    (): ColumnsType<MdfConfigResponse> =>
      columns.filter((c) => c.key === lang || !c.key),
    [lang]
  );

  const columns: ColumnsType<MdfConfigResponse> = [
    {
      title: t('name'),
      dataIndex: 'nameTh',
      key: 'th',
      width: '32%',
      sorter: {
        compare: (a, b) => a.nameTh.localeCompare(b.nameTh),
      },
    },
    {
      title: t('name'),
      dataIndex: 'nameEn',
      key: 'en',
      width: '32%',
      sorter: {
        compare: (a, b) => a.nameEn.localeCompare(b.nameEn),
      },
    },
    {
      title: t('value'),
      dataIndex: 'value',
      width: '20%',
      sorter: {
        compare: (a, b) => (a.value || '').localeCompare(b.value || ''),
      },
    },
    {
      title: t('System Type'),
      dataIndex: 'systemType',
      width: '12%',
      sorter: {
        compare: (a, b) =>
          (a.systemType || '').localeCompare(b.systemType || ''),
      },
    },
    {
      title: t('Config Type'),
      dataIndex: 'configType',
      width: '12%',
      sorter: {
        compare: (a, b) => a.configType.localeCompare(b.configType),
      },
    },
    {
      title: t('Sequence'),
      dataIndex: 'sequence',
      width: '10%',
      sorter: {
        compare: (a, b) => a.sequence - b.sequence,
      },
    },
    {
      title: t('status'),
      dataIndex: 'status',
      width: '8%',
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
    },
    {
      title: 'Action',
      width: '6%',
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const handleSelectRow = (record: MdfConfigResponse) => {
    return {
      onClick: () => {
        handleClick(record.id);
        showModal();
      },
    };
  };

  return (
    <>
      <ButtonAdd handleClick={handleClick} showModal={showModal} />
      <Table
        className="selectable-table"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        onRow={handleSelectRow}
        columns={filterColumnByLang()}
        dataSource={onSearch<DataResponse>(data, [
          lang === 'th' ? 'nameTh' : 'nameEn',
          'systemType',
          'configType',
        ])}
        loading={loading}
        size="small"
        sortDirections={['ascend', 'descend', 'ascend']}
        data-testid="mdf-config-table"
        rowKey="id"
      />
      <ModalCmp
        visible={visible}
        title={id ? t('edit') : t('button.add')}
        handleCancel={hideModal}
        width={700}
      >
        <MdfConfigForm id={id} handleCancel={hideModal} />
      </ModalCmp>
    </>
  );
};
