import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { finalize } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import { ButtonSet } from '@components/ButtonSet';
import { ApiService } from '@core/services/api.service';
import {
  DcaConfigRequest,
  DcaConfigFormProps,
  DcaConfigResponseBody,
} from '@core/@models/DcaConfigModel';
import { useConfigDcaContext } from '@contexts/ConfigDcaContext';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 15 },
};

export const DcaConfigForm: React.FC<DcaConfigFormProps> = ({
  id,
  handleCancel,
  forceFetchData,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const { companyTypes } = useConfigDcaContext();
  const [data, setData] = useState<DcaConfigResponseBody>();
  const apiService = useMemo(() => new ApiService('/companies'), []);

  useEffect(() => {
    getDataById();
  }, [id]);

  const getCompanyTypeLabel = (key: string) => {
    return companyTypes.filter((c) => c.value === key)[0]?.label;
  };

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, DcaConfigResponseBody>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DcaConfigResponseBody) => {
          setData(result);
          form.setFieldsValue({
            ...result,
            companyType: getCompanyTypeLabel(result.companyType),
          });
        },
      });
  };

  const onFinish = (formData: DcaConfigRequest) => {
    const requestData = { ...data, dcaFlag: formData.dcaFlag };
    setLoading(true);
    apiService
      .updateData(requestData)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          forceFetchData();
          handleCancel();
        },
      });
  };

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      data-testid="dca-config-form"
    >
      <Form.Item name="code" label={t('code')}>
        <Input disabled={true} data-testid="code-input" />
      </Form.Item>
      <Form.Item name="nameTh" label={t('nameTh')}>
        <Input disabled={true} data-testid="name-th-input" />
      </Form.Item>
      <Form.Item name="nameEn" label={t('nameEn')}>
        <Input disabled={true} data-testid="name-en-input" />
      </Form.Item>
      <Form.Item name="companyType" label={t('companyType')}>
        <Select disabled={true} data-testid="company-type-dropdown" />
      </Form.Item>
      <Form.Item name="dcaFlag" label="DCA Flag" valuePropName="checked">
        <Checkbox data-testid="dca-flag-checkbox" />
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
