import { ButtonAdd } from '@components/ButtonAdd';
import { ConfirmModal } from '@components/ModalCmp';
import { useConfigMenuContext } from '@contexts/ConfigMenuContext';
import { MenuResponse } from '@core/@models/MenuModel';
import { ApiService } from '@core/services/api.service';
import { useModalManagement } from '@helpers/use-modal-management';
import { Form } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { ModalComponent, ModalComponentProps } from './ModalComponent';
import { OverviewForm, OverviewFormProps } from './OverviewForm';

interface OverviewFormModalProps {
  id: number | null | undefined;
  fetchData: () => void;
  handleId: (id: number | null | undefined) => void;
}

export const OverviewFormModal: React.FC<OverviewFormModalProps> = ({
  id,
  ...props
}) => {
  const { visible, hideModal, showModal } = useModalManagement();
  const [form] = Form.useForm();
  const { fetchedData } = useConfigMenuContext();
  const apiService = useMemo(() => new ApiService('/menus'), []);
  const menusRef = React.useRef<MenuResponse>();

  useEffect(() => {
    if (id !== undefined) {
      form.resetFields();
      showModal();
    }
  }, [id]);

  const handleClose = () => {
    props.handleId(undefined);
    hideModal();
  };

  const handleSubmitData = (data: MenuResponse) => {
    const requestData = { ...data };
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    action$.subscribe({
      next: () => {
        props.fetchData();
        handleClose();
      },
    });
  };

  const onChange = (data: MenuResponse) => {
    menusRef.current = data;
  };

  const onDelete = () => {
    if (id === undefined) return;
    apiService.deleteData<number | null>(id).subscribe({
      next: () => {
        props.fetchData();
        handleClose();
      },
    });
  };

  const showConfirmModal = () => {
    ConfirmModal({ onOk: onDelete });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const result = Object.assign(
          {},
          fetchedData,
          menusRef.current
        ) as MenuResponse;
        handleSubmitData(result);
        handleClose();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const menuModalProps: ModalComponentProps<OverviewFormProps> = {
    componentProps: { form, id, onChange },
    component: OverviewForm,
    modalProps: {
      visible,
      onCancel: handleClose,
    },
    handleSubmit,
    showConfirmModal,
  };

  return (
    <>
      <ButtonAdd showModal={showModal}></ButtonAdd>
      <ModalComponent {...menuModalProps}></ModalComponent>
    </>
  );
};
