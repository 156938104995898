import { useSearchPagination } from '@helpers/use-search-pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Input, Row } from 'antd';
import { Label, Wrapper } from '@components/Search';
import { SearchButton } from '@components/SearchButton';
export const ManageShldSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    handleSearch,
    handleReset,
    id,
    handleInputChange,
  } = useSearchPagination();
  return (
    <Card
      className="search-form"
      title={t('filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        <Col flex="100">
          <Wrapper>
            <Label>I_SHLD_DETAIL</Label>
            <Input
              placeholder={`I_SHLD_DETAIL`}
              name="id"
              onChange={(e) => handleInputChange(e, 'id')}
              allowClear={true}
              value={id}
              data-testid="I_SHLD_DETAIL"
            />
          </Wrapper>
        </Col>
      </Row>
    </Card>
  );
};
