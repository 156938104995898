import { SearchProvider } from '@contexts/SearchProvider';
import { ManageRoleSupportQueryParam } from '@core/@models/ManageRoleSupport';
import React from 'react';
import { ManageRoleSupportList } from './ManageRoleSupportList';
import { ManageRoleSupportSearch } from './ManageRoleSupportSearch';

export const ManageRoleSupport: React.FC = () => {
  const initialParam: ManageRoleSupportQueryParam = {
    loginName: null,
    email: null,
    roleId: null,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<ManageRoleSupportQueryParam> initialParam={initialParam}>
      <ManageRoleSupportSearch />
      <ManageRoleSupportList />
    </SearchProvider>
  );
};
