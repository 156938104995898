import { Label, Wrapper } from '@components/Search';
import { SearchButton } from '@components/SearchButton';
import { useConfigDcaContext } from '@contexts/ConfigDcaContext';
import { useSearchPagination } from '@helpers/use-search-pagination';
import { filterOption } from '@helpers/utils';
import { Card, Col, Input, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DcaConfigSearch: React.FC = () => {
  const { t } = useTranslation(['common']);

  const { companyTypes } = useConfigDcaContext();

  const {
    company,
    companyType,
    handleInputChange,
    handleSelectChange,
    handleSearch,
    handleReset,
  } = useSearchPagination();

  return (
    <Card
      className="search-form"
      title={t('filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        <Col flex="100">
          <Wrapper>
            <Label>{t('code')}</Label>
            <Input
              allowClear={true}
              name="company"
              value={company}
              onChange={(e) => handleInputChange(e, 'company')}
              placeholder={`${t('code')}...`}
              data-testid="code-search-input"
            />
          </Wrapper>
        </Col>
        <Col flex="100">
          <Wrapper>
            <Label>{t('companyType')}</Label>
            <Select
              allowClear
              showSearch
              options={companyTypes}
              style={{ width: '100%' }}
              value={companyType || undefined}
              onChange={(value) => handleSelectChange(value, 'companyType')}
              placeholder={`${t('selectAll')}...`}
              optionFilterProp="options"
              filterOption={filterOption}
              data-testid="company-type-search-dropdown"
            />
          </Wrapper>
        </Col>
      </Row>
    </Card>
  );
};
