import React from 'react';
import { DomainList } from './DomainList';
import { useDataFetching } from '@helpers/use-data-fetching';
import { Search, TYPE } from '@components/Search';
import { DataContext } from '@contexts/DataContext';

export const Domain: React.FC = () => {
  const dataFetching = useDataFetching('/domains');
  const fieldProperties = [
    {
      labelName: 'name',
      name: 'domain',
      type: TYPE.INPUT,
    },
    {
      labelName: 'key',
      name: 'key',
      type: TYPE.INPUT,
    },
  ];

  return (
    <DataContext.Provider value={dataFetching}>
      <Search fieldProps={fieldProperties} />
      <DomainList />
    </DataContext.Provider>
  );
};
